import dayjs from "dayjs";

let xml2js = require("xml2js");

let parseError

const parseMacSpx = async (xml) => {
    parseError = null
    let system = {
        // rawData: xml
    }
    let result = await xml2js.parseStringPromise(xml, {
        tagNameProcessors: [
            (tag) => {
                return tag === "integer" ? "string" : tag;
            },
        ],
    }).catch(error => {
        parseError = error
        system.parseError = parseError
        return system
    })
    // console.log(result)
    system.scanned = dayjs(result.plist.array[0].dict[0].date).format()
    // console.log({result})
    result.plist.array[0].dict.forEach((row) => {
        let text = JSON.stringify(row);
        if (text.match(/hardware_overview/)) {
            let hardware = {};
            row.array[1].dict[0].key.forEach((item, index) => {
                hardware[item] = row.array[1].dict[0].string[index];
            });
            delete hardware._name;
            system.hardware = hardware;
        } else if (text.match(/os_overview/)) {
            let os = {};
            row.array[1].dict[0].key.forEach((item, index) => {
                os[item] = row.array[1].dict[0].string[index];
            });
            delete os._name;
            system.os = os;
        } else if (text.match(/spdisplays/)) {
            system.displays = [];
            // console.log(row)
            if (row.array[1].dict[0].array) {
                row.array[1].dict[0].array.forEach((display) => {
                    let monitor = {};
                    if (display.dict) {
                        display.dict.forEach((item) => {
                            if (item.key) {
                                item.key.forEach((key, index) => {
                                    monitor[key] = item.string[index];
                                });
                                system.displays.push(monitor);
                            }
                            // r[item]=row.array[1].dict[0].string[index]
                        });
                    }
                });
            }
            // console.log(row.array[1].dict[0].array)
        }
    });

    // console.log(system)

    // let result = convert.xml2json(system.rawData, {compact: true, spaces: 4})
    let speed = +((system.hardware.current_processor_speed || '').split(
        " "
    )[0] || 0) ;
    let cpu = system.hardware.cpu_type || system.hardware.chip_type ||'Unknown';
    let ram = system.hardware.physical_memory.match(/GB$/)
        ? +system.hardware.physical_memory.replace(/GB$/, "")
        : +system.hardware.physical_memory.replace(/MB$/, "") / 1024;
    let cpuProcessors = +system.hardware.number_processors || 'Unknown';
    let osVersion = system.os.os_version.split(" ")[1].split(".");
    osVersion.pop();
    osVersion = +osVersion.join(".");

    let cpuSpeed = +((system.hardware.current_processor_speed||'').split(' ')[0] || 0) 

    if (cpu === 'Apple M1'){
        cpuSpeed = 3.2
        cpuProcessors = 4
    }

    let results = {
        scanned: system.scanned,
        os: "MacOS",
        osName: system.os.os_version,
        manufacturer: "Apple",
        model: system.hardware.machine_model || 'Unknown',
        speed,
        cpu,
        cpuSpeed,
        cpuBrand: cpu.match('Intel') ? 'Intel' : cpu.match('AMD')  ? 'AMD' :  cpu.match('Apple')  ? 'Apple' : 'Unknown',
        cpuCores:cpuProcessors,
        cpuProcessors,
        ram,
        osVersion,
        displays: system.displays.length,
        username: system.os.user_name,
        date: dayjs().format('YYYY-MM-DD'),
        additional: {
            uptime: system.os.uptime,
            hardwareUUID: system.hardware.platform_UUID || 'Unknown',
            serialNumber: system.hardware.serial_number || 'Unknown',
        }
    }
    // console.log({ results })

    return results
    // },


}

export default parseMacSpx