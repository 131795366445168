<template>
  <canvas
    style="display: inline-block"
    :width="width"
    :height="height"
  ></canvas>
</template>

<script>
import Chart from "chart.js";

export default {
  data() {
    return {
      chart: null,
    };
  },
  props: ["dataset", "label", "color", "width", "height", "title"],
  watch: {
    dataset() {
      this.chart.options.title.text = this.title;
      this.chart.update();
    },
    title(val) {
      this.chart.options.title.text = val;
      this.chart.update();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.chart = new Chart(this.$el, {
        type: "line",

        data: {
          datasets: [
            {
              data: this.dataset,
              label: this.label,
              backgroundColor: this.color,
            },
          ],
        },
        options: {
          responsive: false,
          title: {
            position: "bottom",
            display: true,
            //   display: this.title ? true : false,
            text: this.title,
          },
          elements: {
                    point:{
                        radius: 0
                    }
                },
          scales: {
            yAxes: [
              {
                display: false,
                gridLines: {
                  display: false,
                },
                ticks: {
                  beginAtZero: true, // minimum value will be 0.
                },
              },
            ],
            xAxes: [
              {
                type: "time",
                gridLines: {
                  display: false,

                  color: "rgba(0, 0, 0, 0)",
                },
                ticks: {
                  display: false, //this will remove only the label
                },
              },
            ],
          },
        },
      });
    });
  },
};
</script>

<style>
</style>