<template>
  <div>
    <div v-if="!processing">
      <div
        class="d-flex justify-center flex-wrap"
        v-if="testSummary.results.headset !== 'missing'"
      >
        <StatCard
          header="Headset"
          :icon="icons.headset"
          :footer="testSummary.headset || 'MISSING'"
          :color="testSummary.headset ? undefined : 'red'"

        ></StatCard>
        <StatCard
          header="Webcam"
          icon="webcam"
          :footer="testSummary.webcam || 'MISSING'"
          :color="testSummary.webcam ? undefined : 'red'"
        ></StatCard>
      </div>
      <div class="d-flex justify-center flex-wrap">
        <StatCard
          v-for="device in session.results.devices"
          :key="device.id"
          small
          :header="device.description"
          :icon="device.icon"
          :footer="device.name"
        ></StatCard>
      </div>
    </div>
    <v-card elevation="6" :loading="processing">
      <v-card-text>
        <div v-if="processing">
          <div>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <p>
            We are requesting your browser to show us your devices. Please be
            sure to click allow when prompted.
          </p>
        </div>
        <div v-else-if="blocked">

              <v-alert type="error">
                You appear to have blocked this site from reading your
            microphone/webcam.
              </v-alert>
              <p>Please unblock this site and allow access to your devices. If you are unsure how, please check out <Article icon="warning" id="browserBlocked" text="this article"/> for instructions on how to fix the issue.</p>

        </div>
        <div v-else-if="!session.results.devices">
          <p>
            Working remotely, we require our team members to have both a USB
            headset and a webcam.
          </p>
          <p>
            Click
            <v-btn x-small dark color="#173042" @click="getDevices">HERE</v-btn>
            and follow the prompts in your browser to determine your devices.
          </p>
          <p>
            <strong>NOTE</strong>: You must click allow in your browser to
            ensure we can detect your devices.
          </p>
        </div>
        <div v-else-if="session.results.devices">
          <p>
            Above are the headsets and webcams we have detected as well as all
            other devices broken out. If you do not see your device listed,
            please try unplugging it and plugging it into a different USB port.
            Once done, this should automattically recan. <strong>You need to see a headset and a webcam to meet our requirements</strong>
          </p>
          <p>
            You can manually rescan by clicking
            <v-btn x-small outlined dark color="#173042" @click="getDevices"
              >HERE</v-btn
            >.
          </p>
        </div>
        <!-- <CheckDevices @complete="updateResults" @processing="onProcessing">
          <div slot="intro">
            <p>
              Working remotely, we require our team members to have both a USB
              headset and a webcam. Click
              <v-btn x-small  dark  color="#173042" >HERE</v-btn> and follow the
              prompts in your browser to determine your devices.
            </p>
          </div>

          <div slot="blocked">
            <div class="d-flex justify-center">
              <p>Opps. Blocked.</p>
            </div>
          </div>
          <div slot="processing">
            <div class="d-flex justify-center">
              <div>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <p>
                We are requesting your browser to show us your devices. Please
                be sure to click allow when prompted.
              </p>
            </div>
          </div>
          <div slot="complete">
            <p>
              Above are the headsets and webcams we have detected as well as all
              other devices broken out. If you do not see your device listed,
              please try unplugging it and plugging it into a different USB
              port. Once done, this should automattically recan.
            </p>
            <p>
              You can manually rescan by clicking
              <v-btn x-small outlined dark  color="#173042">HERE</v-btn>.
            </p>
          </div>
        </CheckDevices> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import CheckDevices from "../components/CheckDevices";
import getDevices from "../utils/getDevices";
import StatCard from "../components/StatCard";
import icons from "../utils/icons";
import Article from "../components/Article";

import { mapGetters, mapState } from "vuex";

export default {
  name: "Devices",
  data() {
    return {
      processing: false,
      blocked: false,
      payload: null,
      error: null,
      devices: null,
      icons,
    };
  },
  components: {
    // CheckDevices,
    Article,
    StatCard,
  },
  methods: {
    onProcessing(bool) {
      this.processing = bool;
    },
    updateResults(data) {
      let key = "devices";
      this.$store.dispatch("updateResults", { key, data });
    },
    isDefault(device) {
      return (
        device.communicationDevicePlayback ||
        device.communicationDeviceRecording ||
        device.defaultDevicePlayback ||
        device.defaultDeviceRecording ||
        device.webcam
      );
    },
    getIcon(device) {
      if (device.webcam) {
        return this.icons.mdiWebcam;
      } else if (device.name.match("Display")) {
        return this.icons.mdiMonitorSpeaker;
      } else if (device.bluetooth) {
        return this.icons.mdiBluetooth;
      } else if (device.internal) {
        return this.icons.mdiLaptop;
      } else if (device.description === "Headset") {
        return this.icons.mdiHeadset;
      } else if (device.description === "Microphone") {
        return this.icons.mdiMicrophone;
      } else if (device.description === "Headphones") {
        return this.icons.mdiHeadphones;
      } else if (device.speaker && !device.microphone) {
        return this.icons.mdiSpeaker;
      } else if (!device.speaker && device.microphone) {
        return this.icons.mdiMicrophone;
      } else if (device.speaker && device.microphone) {
        return this.icons.mdiHeadset;
      }
      return null;
    },
    async isBlocked() {
      if (navigator.permissions) {
        let microphonePerms = await navigator.permissions.query({
          name: "microphone",
        })
        .catch((err) => {
          console.log({ err }, "microphone");
        });
        return microphonePerms.state && microphonePerms.state === "denied";
      } else {
        return true;
      }
    },
    async getDevices() {
      try {
        if (this.processing) {
          return;
        }
        this.error = null;
        this.blocked = await this.isBlocked();
        this.updateResults(null);
        if (this.blocked) {
          this.processing = false;
          return;
        }
        this.processing = true;
        this.devices = null;
        this.loaded = false;
        let data = await getDevices().catch((err) => {
          this.blocked = true;
          throw err;
        });
        this.blocked = await this.isBlocked();
        if (this.blocked) {
          this.processing = false;
          return;
        }
        this.devices = data;
        this.complete = true;
        this.processing = false;
        this.loaded = true;
        this.updateResults(data || null);
      } catch (err) {
        this.processing = false;
        this.blocked = err.error === "Permission denied";
        if (!this.blocked) {
          this.error = err.error || err;
        }
        this.updateResults(null);
      }
    },
  },
  computed: {
    ...mapGetters(["testSummary"]),
    ...mapState(["session"]),
  },
  async created() {
    if (!navigator?.mediaDevices) {
      return;
    }
    navigator.mediaDevices.ondevicechange = () => {
      this.getDevices();
    };

    if (navigator.permissions) {
      let microphonePerms = await navigator.permissions.query({
        name: "microphone",
      });
      microphonePerms.onchange = () => {
        if (microphonePerms.state && microphonePerms.state !== "denied") {
          this.getDevices();
        }
      };
      let webcamPerms = await navigator.permissions.query({
        name: "camera",
      });
      webcamPerms.onchange = () => {
        if (webcamPerms.state && webcamPerms.state !== "denied") {
          this.getDevices();
        }
      };
    }
  },
};
</script>

<style>
</style>