<template>
  <div>
    <p>Welcome back!</p>
    <p>Below is the history of the tests we have for you.</p>
    <p>You may need to re-run this test if any of these are true:</p>
    <ul class="mb-3">
        <li>You do not have a passing test</li>
        <li>You have moved</li>
        <li>You have a new PC or hardware (headset, webcam, monitor, etc)</li>
        <li>You have changed your Internet Service Provider (ISP) or internet plan</li>
    </ul>
    <p>If any of these apply, you can run a new test by clicking <v-btn @click="newTest" x-small dark color="primary" >HERE</v-btn>.
</p>
    <!-- <pre>{{rows}}</pre> -->
     <v-toolbar dark color="primary"
  dense>
    <h3>Results</h3>

      </v-toolbar>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Start Date</th>
            <th class="text-left">Completed</th>
            <th class="text-left">Pass/Fail</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in rows" :key="item.id">
            <td>{{ item.startTime | formatTimestamp("MMM D, YYYY h:mma") }}</td>
            <td>
              <v-icon :color="item.summary.complete ? 'green' : 'red'">{{
                item.summary.complete
                  ? "mdi-clipboard-check"
                  : "mdi-close-octagon"
              }}</v-icon>
              {{ item.endTime | formatTimestamp("MMM D, YYYY h:mma") }}
            </td>
            <td>
              <v-icon :color="item.summary.pass ? 'green' : 'red'">{{
                item.summary.pass ? "mdi-clipboard-check" : "mdi-close-octagon"
              }}</v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      rows: [],
    };
  },
  computed: {
    ...mapState(["history"]),
    ...mapGetters(["saveDoc"]),
  },
  watch: {
    history: {
      immediate: true,
      deep: true,
      handler(val) {
        let result = [];
        let currentDoc = this.saveDoc;
        let found = false;
        val.forEach((item) => {
          if (currentDoc && item.id === currentDoc.id) {
            found = true;
            result.push(currentDoc);
            console.log("(((((((((((REPLACE}}}}}}}}}}");
          } else {
            console.log("just pushing");
            result.push(item);
            //   result[index] = val;
          }
        });

        if (!found && currentDoc && currentDoc.id) {
          console.log("APPENDING");
          result.unshift(currentDoc);
        }
        console.log("SETTING RESULT", { result });
        this.rows = result;
      },
    },
    saveDoc: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log("0000000000000000000", { val }, val.id);
        let result = [];
        let found = false;
        this.history.forEach((item) => {
          if (val && item.id === val.id) {
            found = true;
            result.push(val);
            console.log("(((((((((((REPLACE}}}}}}}}}}");
          } else {
            console.log("just pushing");
            result.push(item);
            //   result[index] = val;
          }
        });

        if (!found && val && val.id) {
          console.log("APPENDING");
          result.unshift(val);
        }
        console.log("SETTING RESULT", { result });
        this.rows = result;
      },
    },
  },
  methods: {
    newTest() {
        console.log('NEW TEST DELETE')
      // this.$store.commit("RESET");
      // this.$router.push({
      //   name: "Test",
      //   params: {
      //     step: "location",
      //   },
      // });
    },
  },
  mounted() {
    console.log("MOUNTED", "***********");
  },
};
</script>
