<template>
  <div v-if="open" class="popup">
    <slot />
  </div>
</template>

<script>
export default {
  name: "PopUp",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    boxWidth: {
      type: Number,
      default: 500,
    },
    boxHeight: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      timer: null,
      closeTimer: null,
      windowRef: window,
      displays: {},
      currentDisplay: null,
      viewBox: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        devicePixelRatio: 1,
      },
      box: {
        screenX: 0,
        screenY: 0,
      },
    };
  },
  watch: {
    open(newOpen) {
      if (newOpen) {
        this.openPortal();
      } else {
        this.closePortal();
      }
    },
  },
  methods: {
    emitData() {
      this.$emit("input", {
        displays: this.displays,
        // viewBox: this.viewBox,
        currentDisplay: this.currentDisplay,
        // box: this.box,
      });
    },
    setViewBox() {
      // let minX = 0;
      // let maxX = 0;
      // let minY = 0;
      // let maxY = 0;
      // Object.keys(this.displays).forEach((id) => {
      //   let display = this.displays[id];
      //   if (display.x < minX) {
      //     minX = display.x;
      //   }
      //   if (display.y < minY) {
      //     minY = display.y;
      //   }
      //   if (display.x + display.width > maxX) {
      //     maxX = display.x + display.width;
      //   }
      //   if (display.y + display.height > maxY) {
      //     maxY = display.y + display.height;
      //   }
      // });
      // this.viewBox.x = minX;
      // this.viewBox.y = minY;
      // this.viewBox.width = maxX - minX;
      // this.viewBox.height = maxY - minY;
    },
    resetDisplayPosition() {
      //       let primary = Object.keys(this.displays).filter(id => id=='0x0')[0]
      //       let devicePixelRatio = primary ? this.displays['0x0'].devicePixelRatio : 1
      //        Object.keys(this.displays).forEach()
      //       console.log({primary,devicePixelRatio})
      //       Object.keys(this.displays).forEach((d) => {
      //         let offsetX =
      //           this.displays[d].availLeft * devicePixelRatio -
      //           this.displays[d].availLeft;
      //         let offsetY =
      //           this.displays[d].availTop * devicePixelRatio -
      //           this.displays[d].availTop;
      //       console.log(offsetX, offsetY);
      // this.displays[d].x = this.displays[d].availLeft
      //         // + offsetX;
      //         this.displays[d].y = this.displays[d].availTop
      //         // + offsetY;
      //         if (
      //           this.currentDisplay === d &&
      //           (this.box.offsetX !== offsetX || this.box.offsetY != offsetY)
      //         ) {
      //         //   console.log("SET BOX", offsetX, offsetY);
      //         //   this.$set(this.box, "offsetX", offsetX);
      //         //   this.$set(this.box, "offsetY", offsetY);
      //         //   console.log(JSON.stringify(this.box), offsetX, offsetY);
      //            this.box = {...this.box, ...{offsetX,offsetY}}
      //           this.emitData();
      //         }
      //       });
      //       this.setViewBox();
    },
    processWindowData() {
      let id = `${this.windowRef.screen.availLeft}x${this.windowRef.screen.availTop}`;
      console.log(id)
      this.currentDisplay = id;
      let primary = id === "0x0";
      let width = this.windowRef.devicePixelRatio * this.windowRef.screen.width;
      let height =
        this.windowRef.devicePixelRatio * this.windowRef.screen.height;
      if (
        this.windowRef.screen.availLeft === 0 &&
        this.windowRef.screen.availTop === 0
      ) {
        // this.$set(this.viewBox,'devicePixelRatio',this.devicePixelRatio)
        this.viewBox.devicePixelRatio = this.windowRef.devicePixelRatio;
        // set all displays relative to root
      }
            console.log('PREDISPLAY')

      let display = {
        id,
        primary,
        x: this.windowRef.screen.availLeft * this.windowRef.devicePixelRatio,
        y: this.windowRef.screen.availTop * this.windowRef.devicePixelRatio,
        devicePixelRatio: this.windowRef.devicePixelRatio,
        availLeft: this.windowRef.screen.availLeft,
        availTop: this.windowRef.screen.availTop,
        availWidth: this.windowRef.screen.availWidth,
        availHeight: this.windowRef.screen.availHeight,
        colorDepth: this.windowRef.screen.colorDepth,
        orientation: {
          angle: this.windowRef?.screen?.orientation?.angle || null,
          type: this.windowRef?.screen?.orientation?.type || null,
        },
        pixelDepth: this.windowRef.screen.pixelDepth,
        screenWidth: this.windowRef.screen.width,
        screenHeight: this.windowRef.screen.height,
        width,
        height,
      };

      this.currentDisplay = id;

      let box = {
        // display: id,
        screenX: this.windowRef.screenX,
        // (this.windowRef.screenX - display.availLeft) *
        //   display.devicePixelRatio +
        // display.x,
        screenY: this.windowRef.screenY,
        // (this.windowRef.screenY - display.availTop) *
        //   display.devicePixelRatio +
        // display.y,
        // offsetX: this.box.offsetX,
        // offsetY: this.box.offsetY,
      };
                  console.log('this.displays',this.displays)

      if (
        !this.displays[id] ||
        JSON.stringify(display) != JSON.stringify(this.displays[id])
      ) {
        this.$set(this.displays, id, display);
        // this.resetDisplayPosition();
        this.displays[id] = display;
        this.$emit("input", {
          displays: this.displays,
          // viewBox: this.viewBox,
          currentDisplay: this.currentDisplay,
          // box: box,
        });
        this.closePortal();
      }
      if (JSON.stringify(box) != JSON.stringify(this.box)) {
        this.box = { ...this.box, ...box };
        // this.resetDisplayPosition()
        this.$emit("move", {
          box,
          currentDisplay: this.currentDisplay,
        });
      }
    },
    openPortal() {
      this.windowRef = window.open(
        "",
        "Detect Monitors",
        `resizable=no,width=${this.boxWidth},height=${this.boxHeight},left=${
          window.screen.width / 2 - this.boxWidth / 2
        },top=${
          window.screen.height / 2 - this.boxHeight / 2
        },location=no,scrollbars=no`
      );

      let linkrels = document.getElementsByTagName("link");

      for (let i = 0, max = linkrels.length; i < max; i++) {
        if (linkrels[i].rel !== "prefetch" && linkrels[i].rel !== "preload") {
          let thestyle = document.createElement("link");
          let attrib = linkrels[i].attributes;
          for (let j = 0, attribmax = attrib.length; j < attribmax; j++) {
            thestyle.setAttribute(attrib[j].nodeName, attrib[j].nodeValue);
          }
          this.windowRef.document.head.appendChild(thestyle);
        }
      }
      let styles = document.getElementsByTagName("style");

      for (let i = 0, max = styles.length; i < max; i++) {
        let thestyle = document.createElement("style");
        thestyle.innerText = styles[i].innerText;
        this.windowRef.document.head.appendChild(thestyle);
      }

      this.windowRef.document.title = "Detect Monitors";
      this.windowRef.addEventListener("beforeunload", this.closePortal);
      this.windowRef.addEventListener("resize", () => {
        // this.windowRef.resizeTo(this.boxWidth, this.boxHeight);
      });

      this.windowRef.addEventListener("onfullscreenchange", () => {
        this.windowRef.resizeTo(this.boxWidth, this.boxHeight);
      });

      this.windowRef.resizeTo(this.boxWidth, this.boxHeight);
      // magic!
      let app = this.windowRef.document.createElement("div");
      app.setAttribute("data-app", "true");
      app.classList = "v-application v-application--is-ltr theme--light";
      this.windowRef.document.body.appendChild(app);

      app.appendChild(this.$el);
      this.timer = setInterval(() => {
        if (!this.windowRef) {
          return;
        }
        this.processWindowData();
        // console.log("PRE FOCUS", this.windowRef, this.windowRef.focus);
        // if (this.windowRef) {
        //   this.windowRef.focus();
        // }
      }, 100);
      this.closeTimer = setTimeout(() => {
        this.closePortal();
      }, 1000 * 60);
    },
    closePortal() {
      clearInterval(this.timer);
      clearTimeout(this.closeTimer);
      this.$emit("close");
      if (this.windowRef) {
        this.windowRef.close();
        this.windowRef = null;
      }
      this.windowRef = null;
      return true;
    },
  },
  mounted() {
    this.processWindowData();
    if (this.open) {
      this.openPortal();
    }
  },
  beforeDestroy() {
    this.closePortal();
  },
};
</script>
<style>
</style>