<template>
  <div>
    <!-- <pre>{{user}}</pre> -->
    <!-- <div class="text-center mt-12">
      <v-rating
        color="#86AC4F"
        background-color="#D0D3D4"
        empty-icon="$ratingFull"
        hover
        large
      ></v-rating>
    </div>
    <v-alert border="top" color="red lighten-2" dark>
      I'm an alert with a top border and red color
    </v-alert>

    <v-card
      elevation="2"
      :loading="true"
      min-height="300"
      class="d-flex flex-column"
    >
      <v-card-title>Title</v-card-title>
      <v-card-subtitle>subtitle</v-card-subtitle>
      <v-card-text class="flex-grow-1">text</v-card-text>
      <v-card-actions class="d-flex align-end">
        <v-btn outlined rounded text> Button </v-btn>
      </v-card-actions>
    </v-card> -->

    <!-- <v-card width="800" height="400">
      <v-card-text> -->
    <!-- {{testSummary.complete}} - 
        {{testSummary}} -->
    <v-tabs
      v-model="tab"
      background-color="#173042"
      centered
      dark
      icons-and-text
      :show-arrows="innerWidth < 850"
    >
      <v-tabs-slider></v-tabs-slider>
      <template>
        <v-tab to="/test/intro" v-if="testSummary.complete < .5 || $route.params.step === 'intro'">
          Intro
          <v-icon>mdi-star-box-outline</v-icon>
        </v-tab>
        <!-- <v-tab v-if="!user" to="/test/login">
          Login
          <v-icon>mdi-account</v-icon>
        </v-tab> -->

        <!-- <v-tab href="#questions">
          Questions
          <v-icon>mdi-format-list-bulleted-type</v-icon>
        </v-tab> -->
      </template>
      <template>
        <v-tab to="/test/confirm" v-if="testSummary.complete < .5 || $route.params.step === 'confirm' || $route.params.step === 'intro'"> 
          Confirm
          <v-icon>mdi-signature-freehand</v-icon>
        </v-tab>

        <v-tab to="/test/computer" :disabled="!testSummary.osName">
          Computer
          <v-icon>mdi-desktop-classic</v-icon>
        </v-tab>

        <v-tab to="/test/devices" :disabled="!testSummary.headset">
          Devices
          <v-icon>mdi-usb</v-icon>
        </v-tab>

        <v-tab to="/test/displays" :disabled="!testSummary.displays">
          Monitors
          <v-icon>mdi-monitor</v-icon>
        </v-tab>
                <v-tab to="/test/location" :disabled="!testSummary.location">
          Location
          <v-icon>mdi-map-marker</v-icon>
        </v-tab>

        <v-tab to="/test/internet" :disabled="!testSummary.downloadSpeed">
          Internet
          <v-icon>mdi-wan</v-icon>
        </v-tab>
        <v-tab to="/test/mobile" :disabled="!testSummary.mobile">
          Mobile
          <v-icon>mdi-cellphone</v-icon>
        </v-tab>

        <v-tab to="/test/results" :disabled="testSummary.complete < 1">
        <!-- <v-tab to="/test/results"> -->
          Results
          <v-icon>mdi-clipboard-text</v-icon>
        </v-tab>
        <v-tab to="/test/history" v-if="history.length">
          History
          <v-icon>mdi-clipboard-text</v-icon>
        </v-tab>
        <v-tab to="/test/apply" v-if="testSummary.pass === true">
          Apply
          <v-icon>mdi-notebook-edit-outline</v-icon>
        </v-tab>

      </template>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="/test/intro">
        <v-card flat>
          <v-card-text>
            <Intro />
          </v-card-text>
          <NextStep
            :to="testSummary.complete === 1 ? '/test/results' :'/test/confirm'"
            text="I am ready! Let's begin"
          />
        </v-card>
      </v-tab-item>

      <!-- <v-tab-item value="/test/login">
        <v-card flat>
          <v-card-text>
            <Login />
          </v-card-text>
          <NextStep :disabled="!user" to="/test/accept" />
        </v-card>
      </v-tab-item> -->
      <!-- <v-tab-item value="questions">
          <v-card flat>
            <v-card-text > questions </v-card-text>
          </v-card>
        </v-tab-item> -->
      <v-tab-item value="/test/confirm">
        <v-card flat>
          <v-card-text>
            <Questions />
          </v-card-text>

          <NextStep :disabled="!testSummary.accepted" :to="testSummary.complete === 1 ? '/test/results' :'/test/computer'" />
        </v-card>
      </v-tab-item>
      
      <v-tab-item value="/test/computer">
        <v-card flat>
          <v-card-text>
            <Computer />
          </v-card-text>
          <NextStep :to="testSummary.complete === 1 ? '/test/results' :'/test/devices'" :disabled="!testSummary.cpu" />
        </v-card>
      </v-tab-item>

      <v-tab-item value="/test/devices">
        <v-card flat>
          <v-card-text>
            <Devices />
          </v-card-text>
          <NextStep :to="testSummary.complete === 1 ? '/test/results' :'/test/displays'" :disabled="!session.results.devices" />
        </v-card>
      </v-tab-item>

      <v-tab-item value="/test/displays">
        <v-card flat>
          <v-card-text>
            <Displays />
          </v-card-text>
          <NextStep :to="testSummary.complete === 1 ? '/test/results' :'/test/location'" :disabled="!testSummary.displays"
          delay="3"
           />
        </v-card>
      </v-tab-item>

      <v-tab-item value="/test/location">
        <v-card flat>
          <v-card-text>
            <Location />
          </v-card-text>
          <NextStep :to="testSummary.complete === 1 ? '/test/results' :'/test/internet'" :disabled="!testSummary.location" />
        </v-card>
      </v-tab-item>

      <v-tab-item value="/test/internet">
        <v-card flat>
          <v-card-text>
            <Internet />
          </v-card-text>
          <NextStep :to="testSummary.complete === 1 ? '/test/results' :'/test/mobile'" :disabled="!testSummary.uploadSpeed" />

        </v-card>
      </v-tab-item>

      <v-tab-item value="/test/mobile">
        <v-card flat>
          <v-card-text>
            <Mobile/>
          </v-card-text>
          <NextStep to="/test/results" :disabled="!testSummary.mobile" />
        </v-card>
      </v-tab-item>

      <v-tab-item value="/test/results">
        <v-card flat>
          <v-card-text>
            <Results />
          </v-card-text>
                    <NextStep v-if="testSummary.pass===true" to="/test/apply" text="Apply Now" />

        </v-card>
      </v-tab-item>


      <v-tab-item value="/test/history">
        <v-card flat>
          <v-card-text>
            <History />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item value="/test/apply">
        <v-card flat>
          <v-card-text>
            <Apply />
          </v-card-text>
        </v-card>
      </v-tab-item>



    </v-tabs-items>
    <v-toolbar color="primary" dark>
      <v-progress-linear
        height="10"
        color="green"
        striped
        v-model="progress"
      ></v-progress-linear>
    </v-toolbar>
    <!-- </v-card-text>
    </v-card> -->
  </div>
</template>

<script>
// import Login from "../components/Login";
import Apply from "../steps/Apply";
import Location from "../steps/Location";
import Internet from "../steps/Internet";
import Devices from "../steps/Devices";
import Displays from "../steps/Displays";
import Computer from "../steps/Computer";
import History from "../steps/History";
import Questions from "../steps/Questions";
import Mobile from "../steps/Mobile";

import Intro from "../steps/Intro";
import Results from "../components/Results";
import NextStep from "../components/NextStep";
import icons from "../utils/icons";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      tab: null,
      innerWidth: window.innerWidth,
      displayPayload: null,
      progress: 0,
      steps: [
        "intro",
        "login",
        "accept",
        "location",
        "computer",
        "devices",
        "displays",
        "internet",
        "results",
        {
          name: "Intro",
          icon: icons.intro,
        },
        {
          name: "Login",
          icon: icons.user,
        },
        {
          name: "Questions",
          icon: icons.questions,
        },
        {
          name: "Location",
          icon: icons.location,
        },
        {
          name: "Computer",
          icon: icons.user,
        },
        {
          name: "Login",
          icon: icons.user,
        },
        {
          name: "Login",
          icon: icons.user,
        },
      ],
    };
  },
  components: {
    Location,
    Internet,
    Devices,
    Displays,
    Computer,
    Results,
    // Login,
    NextStep,
    Intro,
    History,
    Questions,
    Mobile,
    Apply,
  },
  watch: {
    "testSummary.complete": {
      immediate: true,
      handler(val) {
        this.progress = val * 100;
      },
    },
    tab: {
      immediate: true,
      handler(val) {
        if (val) {
          let step = val;
          // let step =  val.split("")[0]
          if (this.$route.params.step != step) {
            // this.$router.push({
            //   name: "Test",
            //   params: {
            //     step,
            //   },
            // });
          }
        }
      },
    },
    $route: {
      immediate: true,
      handler(val) {
        if (val) {
          this.tab = val.params.step;
        }
      },
    },
  },
  computed: {
    ...mapState(["results", "user", "history", "session"]),
    ...mapGetters(["summary", "saveDoc", "testSummary"]),
    fails() {
      return Object.keys(this.summary.checks)
        .map((key) => {
          let check = this.summary.checks[key];
          return !check.pass && !check.exempt ? check : null;
        })
        .filter((item) => item);
    },
    noNext() {
      switch (this.tab) {
        case "location":
          return this.summary.checks.location.missing;
        case "internet":
          return this.summary.checks.download.missing;
        case "computer":
          return this.summary.checks.cpu.missing;
        case "devices":
          return this.summary.checks.headset.missing;
        case "displays":
          return this.summary.checks.displays.missing;
        case "history":
          return true;
      }
      return false;
    },
  },
  methods: {
    nextTab() {
      let steps = this.steps.filter((step) => {
        if (this.user) {
          return step !== "login";
        } else {
          return step === "intro" || step === "login";
        }
      });
      let index = steps.indexOf(this.tab);
      this.tab = steps[index + 1];
      // this.$router.push({
      //           name:'Test',
      //           params: {
      //             step:steps[index + 1]
      //           }

      // console.log('NEXT TAB')
      // if (this.$route.params.retry) {
      //   console.log('RETRY', this.$route.params)
      //   this.$router.push({
      //     name:'Test',
      //     params: {
      //       step: 'results'
      //     }
      //   })
      //   // this.tab = "results";
      // } else if (this.$store.state.user && step==='login'){
      //     this.$router.push({
      //     name:'Test',
      //     params: {
      //       step:'location'
      //     }
      //   })
      // } else {
      //   this.$router.push({
      //     name:'Test',
      //     params: {
      //       step
      //     }
      // })
      //   // this.tab = step;
      //}
    },
  },
};
</script>

<style>
.break {
  flex-basis: 100%;
  height: 0;
}
</style>