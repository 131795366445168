<template>
  <div>
      <p>Before we begin, we need you to confirm that you are completing this test from your home office. Please click on each statement to confirm they are true. If anyone one of them is not true, please return here when they are to begin the check.</p>
    <div class="pl-5">
    <!-- <v-switch v-model="homeOffice" label="I am in my home office"
          :disabled="testSummary.accepted"
></v-switch> -->
    <v-switch
      v-model="homeComputer"
      label="I am currently on my home office Computer"
      :disabled="testSummary.accepted"
    ></v-switch>
    <v-switch
      v-model="homeNetwork"
      label="I am connected to my home office Internet Service Provider (ISP)"
      :disabled="testSummary.accepted"
    ></v-switch>
    <v-switch
      v-model="vpn"
      label="I am NOT connected to a VPN"
      :disabled="testSummary.accepted"
    ></v-switch>
        <v-switch
      v-model="mobileDevice"
      label="I own and have access to an iOS or Android mobile device (cell phone, tablet, etc) that is not shared"
      :disabled="testSummary.accepted"
    ></v-switch>

    </div>
    <p v-if="testSummary.accepted">Great! Please proceed to the next step.</p>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      // homeOffice: false,
      homeComputer: false,
      homeNetwork: false,
      mobileDevice: false,
      vpn: false,
    };
  },
  watch: {
    "session.accepted": {
      handler(val) {
        if (val) {
          // this.homeOffice = true;
          this.homeComputer = true;
          this.homeNetwork = true;
          this.mobileDevice = true;
          this.vpn = true;
        }
      },
      deep: true,
      immediate: true,
    },
    accepted(val) {
      if (val && !this.session.accepted){
        this.$store.commit("ACCEPT", val);
      }
    },
  },
  computed: {
    ...mapState(["session"]),
    ...mapGetters(['testSummary']),
    accepted() {
      return this.homeComputer && this.homeNetwork && this.mobileDevice && this.vpn
      // && this.homeOffice 
    },
  },
};
</script>

<style>
</style>