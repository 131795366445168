<template>
  <div>
    <div
      v-if="!processing && testSummary.results.downloadSpeed !== 'missing'"
      class="d-flex justify-center"
    >
      <StatCard
        header="Download"
        icon="download"
        :footer="`${testSummary.downloadSpeed} MB/s`"
        :color="$route.params.retry && testSummary.results.downloadSpeed !=='pass' ?   'red' : undefined"
      ></StatCard>
      <StatCard
        header="Upload"
        icon="upload"
        :footer="`${testSummary.uploadSpeed} MB/s`"
        :color="$route.params.retry && testSummary.results.uploadSpeed !=='pass' ?   'red' : undefined"
      ></StatCard>
      <StatCard
        header="Jitter"
        icon="jitter"
        :footer="`${testSummary.jitter} ms`"
        :color="$route.params.retry && testSummary.results.jitter !=='pass' ?   'red' : undefined"
      ></StatCard>
      <StatCard
        header="Latency"
        icon="latency"
        :footer="`${testSummary.latency} ms`"
        :color="$route.params.retry && testSummary.results.latency !=='pass' ?   'red' : undefined"
      ></StatCard>
    </div>
    <v-card elevation="6" :loading="processing">
      <v-card-text>
        <CheckInternet
          @complete="updateResults"
          @running="onRunning"
          @progress="onProgress"
          class="d-flex justify-center text-center flex-wrap"
        >
          <div v-if="processing" slot="processing">
            <div>
              <v-progress-circular
                small
                indeterminate
                color="primary"
              ></v-progress-circular>
              Test is running, please stand by...Currently testing
              {{ payload.requestType }}.
            </div>
          </div>
          <div
            class="d-block text-left"
            slot="intro"
            v-else-if="!testSummary.downloadSpeed"
          >
            <p>
              Now we need to determin both the speed and, more importantly, the
              quality of your internet connection. To ensure you get the best
              results, it is recommended that you are connected with a wired
              connection vs connecting over WiFi. Click
              <v-btn x-small dark  color="#173042">HERE</v-btn>
              when ready to run the test.
            </p>
            <p>
              <strong>NOTE</strong>: Do not navigate away from this page during this test. Doing
              so can negatively impact your results.
            </p>
          </div>
          <div v-else @click="resetInternet" slot="complete">
            <div class="d-flex justify-center">
              <p v-if="$route.params.retry">
                Your internet tests have completed with the results showing
                here. If for some reason you need to run this test again, please
                click
                <v-btn @click="resetInternet" x-small outlined >HERE</v-btn>. Otherwise,
                please click the button below to proceed to the next step.
              </p>
              <p v-else>
                Above are your previous internet test results. To re-run the
                test and resume the process, please click
                <v-btn @click="resetInternet" x-small outlined>HERE</v-btn>. Otherwise,
                please click the button below to proceed to the next step.
              </p>
            </div>
          </div>
        </CheckInternet>
      </v-card-text></v-card
    >
  </div>
</template>

<script>
import CheckInternet from "../components/CheckInternet";
import StatCard from "../components/StatCard";
import { mapGetters } from "vuex";
export default {
  name: "Internet",
  data() {
    return {
      processing: false,
      complete: false,
      blocked: false,
      payload: null,
    };
  },
  components: {
    CheckInternet,
    StatCard,
  },
  methods: {
    onRunning(payload) {
      this.processing = payload ? payload : false;
    },
    onProgress(payload) {
      this.payload = payload || null;
    },
    resetInternet() {},
    updateResults(data) {
      this.processing = false;
      this.complete = true;
      let key = "internet";
      this.$store.dispatch("updateResults", { key, data });
    },
  },
  computed: {
    ...mapGetters(["testSummary"]),
  },
};
</script>

<style>
</style>