// const nameRegExp = new RegExp(/(\((\d-\s)?(.*)\))/gim)
// const namePartsRegExp = new RegExp(/(?<=\().+?(?=\))/gim)
const usbCodeRegExp = new RegExp(/(\(([a-z0-9]{4}:[a-z0-9]{4})\))$/gim)
const bluetoothCodeRegExp = new RegExp(/\(Bluetooth\)/gim)
// const nameRegExp = new RegExp(/(\()(.*?)(()?)(\))(\s)*/gim)
const ExcludedDevices = `(AvStream Media Device)|(OBS Virtual Camera)`

const getIconDescription = (device) => {
    if (device.webcam) {
        return {
            icon: 'mdi-webcam',
            description: 'Webcam'
        }
    } else if (device.name.match("Display")) {
        return  {
            icon: 'mdi-monitor-speaker',
            description: 'Speaker'
        }
    } else if (device.bluetooth) {
        return  {
            icon: 'mdi-bluetooth',
            description: 'Bluetooth'
        }
    } else if (device.internal) {
        return  {
            icon: 'mdi-volume-high',
            description: 'Internal'
        }
    } 
    // else if (device.rawDescription === "Headset") {
    //     return  {
    //         icon: 'mdi-headset',
    //         description: 'Headset'
    //     }
    // } else if (device.rawDescription === "Microphone") {
    //     return  {
    //         icon: 'mdi-microphone',
    //         description: 'Microphone'
    //     }
    // } else if (device.rawDescription === "Headphones") {
    //     return  {
    //         icon: 'mdi-headphones',
    //         description: 'Headphones'
    //     }
    // } 
    else if (device.speaker && !device.microphone) {
        return  {
            icon: 'mdi-speaker',
            description: 'Speaker'
        }
    } else if (!device.speaker && device.microphone) {
        return  {
            icon: 'mdi-microphone',
            description: 'Microphone'
        }
    } else if (device.speaker && device.microphone) {
        return  {
            icon: 'mdi-headset',
            description: 'Headset'
        }
    }
    return null
}

let defaultDeviceObject = {
    id: null,
    brand: null,
    name: null,
    code: null,
    usb: false,
    internal: false,
    headset: false,
    headphones: false,
    bluetooth: false,
    microphone: false,
    speaker: false,
    webcam: false,
    systemAudio: false,
    defaultDevice: false,
    communicationDevice: false,
    communicationDevicePlayback: false,
    communicationDeviceRecording: false,
    defaultDevicePlayback: false,
    defaultDeviceRecording: false,
    icon: null,
    groupId: null,
}

const constraints = {
    audio: true,
    video: true,
}

// const processDevices = (devices) => {
//     let results = {}
    
//     Object.keys(devices).forEach(groupId => {
//         let deviceItems = devices[groupId]
//         let usbCode = usbCodeRegExp.exec(deviceItems[0].label)

//         let nameTest = deviceItems[0].label.replace('(R)', '').replace(usbCodeRegExp, '')
//         let nameTestMatch = (/(\()(.*?)(()?)(\))(\s)*/gim).exec(nameTest)
//         let deviceName = (nameTestMatch ? nameTestMatch[2] || nameTest : nameTest).replace(/^\d-\s/gim, '')

//         let id =  usbCode ? usbCode[2] || usbCode[1] || usbCode[0] : deviceName || groupId

//         let item = results[id] || { ...defaultDeviceObject }

//         item.code = usbCode ? usbCode[2] || usbCode[1] || usbCode[0] : null
//         item.groupId = groupId
//         item.items = deviceItems
//         item.name = deviceName
//         item.brand = deviceName.split(' ')[0]
//         item.usb = item.code ? true : false
//         item.id = item.code || groupId
//         // let label = deviceItems[0].label
//         // let brand = deviceItems[0].label
//         deviceItems.forEach(device => {
//             if (device.label.match('Virtual') || device.label.match('Integrated') || device.label.match('Realtek')) {
//                 item.internal = true
//             }
//             if (device.kind === 'audioinput') {
//                 item.microphone = true
//                 if (device.deviceId === 'default') {
//                     item.defaultDevice = true
//                     item.defaultDeviceRecording = true
//                 } else if (device.deviceId === 'communications') {
//                     item.defaultDevice = true
//                     item.communicationDeviceRecording = true
//                 }
//             } else if (device.kind === 'audiooutput') {
//                 item.speaker = true
//                 if (device.deviceId === 'default') {
//                     item.defaultDevice = true
//                     item.defaultDevicePlayback = true
//                 } else if (device.deviceId === 'communications') {
//                     item.defaultDevice = true
//                     item.communicationDevicePlayback = true
//                 }
//                 if (device.label.match('headphones')){
//                     item.headphones = true
//                 }
//             } else if (device.kind === 'videoinput') {
//                 item.webcam = true
//             }
//             if (!item.bluetooth) {
//                 let match = bluetoothCodeRegExp.exec(device.label)
//                 item.bluetooth = match ? true : false
//             }
//             // if (!defaultDevice) {
//             //     let label = device.label
//             //         .replace(usbCodeRegExp, '')
//             //         .replace(bluetoothCodeRegExp, '')
//             //         .replace(/(\(R\))/g, '').replace(/(\(TM\))/, '')
//             //     let parts = label.match(namePartsRegExp)
//             //     let leftOver = label.replace(namePartsRegExp, '').replace(/\(\)/g, '').trim()
//             //     if (leftOver) {
//             //         let description = leftOver.toLowerCase()
//             //         if (!item.description){
//             //             item.description = description
//             //         } else if (!item.description.match(description)){
//             //             item.description += ' ' + description
//             //         }

//             //         if (leftOver === 'Headset') {
//             //             item.headset = true
//             //         }
//             //     }
//             //     let name = parts ? parts[0] : leftOver
//             //     if (!item.name){
//             //         item.name = name
//             //     } else if (!item.name.match(name)){
//             //         item.name += ' & ' + name
//             //     }
//             // }
//             // if (!item.brand && item.name) {
//             //     item.brand = (item.name || "").split(/[\s(]/)[0]
//             // }
//         })
//         item.icon = getIcon(item)
//         if (item.communicationDevicePlayback && item.communicationDeviceRecording){
//             item.communicationDevice = true
//         }
//         results[id] = item
//         return  results[id]
//     })
//     console.log(JSON.stringify(results, null, 2))
//     // let finalResults = {}
//     // results.forEach(item => {
//     //     let id = item.code || item.groupId || item.id
//     //     finalResults[id] = finalResults[id] || {
//     //         ...defaultDeviceObject, ...{
//     //             brand: item.brand,
//     //             name: item.name,
//     //             code: item.code,
//     //             usb: item.usb,
//     //             internal: item.internal,
//     //             headset: item.headset,
//     //             microphone: item.microphone,
//     //             speaker: item.speaker,
//     //             webcam: item.webcam,
//     //             systemAudio: item.systemAudio,
//     //             rawDescription: item.description,
//     //             communicationDevicePlayback: item.communicationDevicePlayback,
//     //             communicationDeviceRecording: item.communicationDeviceRecording,
//     //             defaultDevicePlayback: item.defaultDevicePlayback,
//     //             defaultDeviceRecording: item.defaultDeviceRecording,
//     //         }
//     //     }
//     //     Object.keys(item).forEach(key => {
//     //         if (item[key] === true) {
//     //             finalResults[id][key] = true
//     //         }
//     //     })
//     //     console.log(finalResults[id])

//     //     if (finalResults[id].microphone && finalResults[id].speaker && !finalResults[id].internal && finalResults[id].usb) {
//     //         finalResults[id].headset = true
//     //     }
//     //     console.log(1)
//     //     finalResults[id].description = finalResults[id].headset ? 'Headset' : finalResults[id].webcam ? 'Webcam' : finalResults[id].internal ? 'System Audio' : finalResults[id].speaker ? 'Speakers' : finalResults[id].microphone ? 'Microphone' : ''
//     //     console.log(2)

//     //     finalResults[id].icon = getIcon(finalResults[id])
//     //     finalResults[id].items.push(item)

//     // })

//     return results

//     // return Object.keys(finalResults).map(key => finalResults[key])

// }

const gotDevices = async () => {
    let devices = {}
    const deviceInfos = await navigator.mediaDevices.enumerateDevices().catch(err => {
        return {error:err}
    })
    deviceInfos.forEach((device) => {
        if (!device.label || device.label.match(ExcludedDevices)) {
            return
        }

        let usbCode = usbCodeRegExp.exec(device.label)
        let nameTest = device.label.replace('(R)', '').replace(usbCodeRegExp, '')
        let nameTestMatch = (/(\()(.*?)(()?)(\))(\s)*/gim).exec(nameTest)
        let deviceName = ((nameTestMatch ? nameTestMatch[2] || nameTest : nameTest).replace(/^\d-\s/gim, '') || '').trim()

        let id =  usbCode ? usbCode[2] || usbCode[1] || usbCode[0] : deviceName || device.groupId
        let item = devices[id] || { ...defaultDeviceObject }

        item.id = id
        item.code = usbCode ? usbCode[2] || usbCode[1] || usbCode[0] : null
        item.groupId = device.groupId || null
        item.name = deviceName
        item.brand = deviceName.split(' ')[0]
        item.usb = item.code ? true : false

        if (device.label.match('Virtual') || device.label.match('Integrated') || device.label.match('Realtek')) {
            item.internal = true
        }
        if (device.kind === 'audioinput') {
            item.microphone = true
            if (device.deviceId === 'default') {
                item.defaultDevice = true
                item.defaultDeviceRecording = true
            } else if (device.deviceId === 'communications') {
                item.defaultDevice = true
                item.communicationDeviceRecording = true
            }
        } else if (device.kind === 'audiooutput') {
            item.speaker = true
            if (device.deviceId === 'default') {
                item.defaultDevice = true
                item.defaultDevicePlayback = true
            } else if (device.deviceId === 'communications') {
                item.defaultDevice = true
                item.communicationDevicePlayback = true
            }
            if (device.label.match('headphones')){
                item.headphones = true
            }
        } else if (device.kind === 'videoinput') {
            item.webcam = true
        }
        if (!item.bluetooth) {
            let match = bluetoothCodeRegExp.exec(device.label)
            item.bluetooth = match ? true : false
        }

        let details = getIconDescription(item)
        item ={...item,...details}
        if (item.communicationDevicePlayback && item.communicationDeviceRecording){
            item.communicationDevice = true
        }
        if (item.microphone && item.speaker && item.usb){
            item.headset = true
        }

        item.items = item.items || []
        item.items.push({
            id,
            deviceId: device.deviceId,
            groupId: device.groupId,
            kind: device.kind,
            label: device.label,
        })

        devices[id] = item
    })
    // console.log(JSON.stringify(devices,null,2))
    if (!Object.keys(devices).length){
        return Promise.reject({error: 'Permission denied'})
    }
    return devices
    // return processDevices(devices)
}

const getDevices = async () => {
    let stream = await navigator.mediaDevices.getUserMedia(constraints)
    .catch(() => {
        // console.log('ERR', err)
        //return Promise.reject({ error: err.message })
    })
    let devices = await gotDevices()
    if (stream && stream.getTracks){
        stream.getTracks().forEach((track) => {
            track.stop()
        })
    }

    return devices
}

export default getDevices