<template>
  <div>
    <div>
      <div class="d-flex justify-center">
        <StatCard
          v-for="(display, key, index) in session.results.displays"
          :key="display.id"
          :header="`Monitor ${index + 1}`"
          icon="mdi-monitor"
          :footer="`${display.width} X ${display.height}`"
        ></StatCard>
      </div>
    </div>
    <div>
      <v-card elevation="6" :loading="processing">
        <v-card-text>
          <CheckDisplays
            @complete="updateResults"
            @change="updateResults"
            @processing="onProcessing"
          >
            <div>
              <p>
                We have detected {{ monitors }}. If you have other monitors,
                click <v-btn x-small dark  color="#173042" >HERE</v-btn> to open a
                detection window and follow the instructions there.
              </p>
            </div>
            <div slot="processing">
              <div class="d-flex justify-center">
                <p>
                  Please look for a popup window. Follow instructions and use
                  that window to detect your monitors.
                </p>
              </div>
            </div>
            <div slot="popup" class="pa-5">
              <v-card>
                <v-card-text>
                  <div class="d-flex justify-center">
                    <StatCard
                      v-for="(display, key, index) in session.results.displays"
                      :key="display.id"
                      :header="`Monitor ${index + 1}`"
                      icon="mdi-monitor"
                      :footer="`${display.width} X ${display.height}`"
                    ></StatCard>
                  </div>
                  <p>
                    Drag this window into your other monitor. Once it detects
                    that monitor, it will close and show the monitor in the main
                    window. Repeat this step for each monitor. Currently we see
                    <strong>{{ monitors }}</strong
                    >.
                  </p>
                  <p>
                    NOTE: This window will auto close in 1 minute if it does not
                    detect another monitor.
                  </p>
                </v-card-text>
              </v-card>
            </div>
          </CheckDisplays>
        </v-card-text></v-card
      >
    </div>
  </div>
</template>

<script>
import CheckDisplays from "../components/CheckDisplays";
import StatCard from "../components/StatCard";
import { mapGetters, mapState } from "vuex";
export default {
  name: "Displays",
  data() {
    return {
      processing: false,
      complete: false,
      blocked: false,
      payload: null,
    };
  },
  components: {
    CheckDisplays,
    StatCard,
  },
  methods: {
    onProcessing(bool) {
      this.processing = bool;
    },
    updateResults(data) {
      this.complete = true;
      let key = "displays";
    let result = {...this.session.results.displays, ...data}
      this.$store.dispatch("updateResults", { key, data:result });
    },
  },
  computed: {
    ...mapGetters(["testSummary"]),
    ...mapState(["session"]),
    monitors() {
      let count = this.testSummary.displays;
      return `${count} monitor${count !== 1 ? "s" : ""}`;
    },
  },
};
</script>

<style>
</style>