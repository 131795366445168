<template>
  <div>
    <div
      class="jv-careersite"
      data-careersite="vipdeskconnect"
      data-force-redirect
      jobvite-iframeParameter="true"
    ></div>
  </div>
</template>
<script src="//jobs.jobvite.com/__assets__/scripts/careersite/public/iframe.js">
</script>

<script>
export default {
  mounted() {
    this.$nextTick(() => {
      (function (win) {
        var anchor, el, src, baseUrl, optionMap, path;

        optionMap = {
          "data-category": "c",
          "data-department": "d",
          "data-location": "l",
          "data-region": "r",
          "data-subsidiary": "s",
          "data-type": "t",
        };

        el = document.querySelector(".jv-careersite");
        if (!el) {
          return;
        }
        var iframeParameter = el.getAttribute("jobvite-iframeParameter");
        if (iframeParameter === "true") {
          iframeParameter = "jobviteiframe";
        } else {
          iframeParameter = "p";
        }

        if (!el.getAttribute("data-careersite")) {
          warn("Jobvite: no careersite detected!");
          return;
        }

        win.Jobvite = win.Jobvite || {};
        baseUrl = win.Jobvite.baseUrl || "//jobs.jobvite.com";
        path = getPath(win.location.search, el.getAttribute("data-page"));
        src = [baseUrl, el.getAttribute("data-careersite"), path].join("/");

        if (!detectMobile(win.navigator.userAgent)) {
          renderIframe();
        } else if (shouldRedirect()) {
          win.location.href = src;
        } else if (!el.firstChild) {
          renderLink();
        }

        // functions -------------------------------------------------------------------

        function renderLink() {
          var link = document.createElement("a");
          link.innerHTML = "View Open Positions";
          link.href = src;
          el.appendChild(link);
        }

        function renderIframe() {
          var iframe, actions;
          iframe = document.createElement("iframe");
          iframe.title = "Jobvite Career Site";
          iframe.frameBorder = 0;
          iframe.style.width = "100%";
          iframe.src = src;
          iframe.id = "jv_careersite_iframe_id";
          iframe.className = "jv-careersite-iframe";
          iframe.name = "jobvite_careersite_iframe";

          while (el.firstChild) {
            try {
              el.removeChild(el.firstChild);
            } catch (err) {
              console.log(err);
            }
          }
          el.appendChild(iframe);

          actions = {
            action_resize: function (data) {
              // adding 25px to prevent iframe scrollbars from appearing
              iframe.style.height = data.height + 25 + "px";
            },
            action_navigate: function (data) {
              if (data.href.match(/^(https?:)?\/\//)) {
                win.location = data.href;
              } else {
                win.location.search = updateQueryStringParameter(
                  win.location.search,
                  iframeParameter,
                  encodeURIComponent(data.href)
                );
              }
            },
            action_scrollToFrame: function () {
              iframe.scrollIntoView();
            },
          };

          addEvent(
            win,
            "message",
            function (e) {
              var action;
              if (e.source !== iframe.contentWindow) {
                return;
              }
              try {
                action = JSON.parse(e.data);
              } catch (err) {
                action = {};
              }
              if (actions["action_" + action.name]) {
                actions["action_" + action.name](action.data);
              }
            },
            false
          );
        }

        function getPath(search, page) {
          var parts = search ? search.substr(1).split("&") : [],
            defaultPage = "",
            len = parts.length,
            extra = ["nl=1"],
            path = page || defaultPage,
            pathParts,
            param,
            prop,
            propValue,
            i;

          for (i = 0; i < len; i++) {
            param = parts[i].split("=");
            if (param[0] === iframeParameter) {
              pathParts = decodeURIComponent(param[1]).split("?");
              path = pathParts[0];
              if (pathParts[1]) {
                extra.push(pathParts[1]);
              }
            } else {
              extra.push(parts[i]);
            }
          }

          for (prop in optionMap) {
            if (optionMap.hasOwnProperty(prop)) {
              propValue = el.getAttribute(prop);
              if (propValue) {
                extra.push(optionMap[prop] + "=" + propValue);
              }
            }
          }
          var forceRedirect = el.getAttribute("data-force-redirect") !== null;
          extra.push("fr=" + forceRedirect);

          if (decodeURI(path).match(/\.[\s\n\r]*\./)) {
            path = defaultPage;
          }

          return path + (extra.length ? "?" + extra.join("&") : "");
        }

        function shouldRedirect() {
          var forceRedirect = el.getAttribute("data-force-redirect") !== null;
          var noRedirect = el.getAttribute("data-no-redirect") !== null;
          var page = el.getAttribute("data-page");

          if (forceRedirect) {
            return true;
          }
          if (noRedirect && page && path.indexOf(page) === 0) {
            return false;
          }
          if (path.charAt(0) !== "?") {
            return true;
          }
          return false;
        }

        function addEvent(obj, ev, fn) {
          if (obj.addEventListener) {
            obj.addEventListener(ev, fn, false);
            return true;
          } else if (obj.attachEvent) {
            return obj.attachEvent("on" + ev, fn);
          }
        }

        function warn(message) {
          if (typeof console !== "undefined" && console !== null) {
            if (typeof console.warn === "function") {
              console.warn(message);
            }
          }
        }

        function updateQueryStringParameter(queryString, key, value) {
          var regex = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
          var separator = queryString.indexOf("?") !== -1 ? "&" : "?";
          if (queryString.match(regex)) {
            return queryString.replace(regex, "$1" + key + "=" + value + "$2");
          } else {
            return queryString + separator + key + "=" + value;
          }
        }

        function detectMobile(useragent) {
          if (useragent.match(/^(?=.*Android)(?=.*Mobile).*/)) {
            return true;
          }
          if (useragent.match(/.*iphone.*/i)) {
            return true;
          }
          return false;
        }
      })(window);
    });
  },
};
</script>

<style>
</style>