<template>
  <div>
    <div class="d-flex justify-center">
      <StatCard
        v-for="device in session.results.mobile"
        :key="device.id"
        :header="getHeader(device.type)"
        :icon="device.osName"
        :footer="device.name"
      ></StatCard>
    </div>
    <!-- <pre>{{session.results.mobile}}</pre> -->
    <template v-if="session.results.mobile">
      <p>
        Great! We have detected {{ session.results.mobile.length }} mobile
        device{{ session.results.mobile.length > 1 ? "s" : "" }}. If you
        registed the wrong device, just open up this site on the other device
        and we will add it to the mix. Otherwise, please proceed to the next
        step.
      </p>
    </template>
    <template v-else>
      <p>Lastly, we need to confirm you have a mobile device.</p>
      <p>
        We use mobile devices (phones, tablets) and a second form of
        authentication when logging into VIPdesk systems. This can be a most any
        device (Android phone, iPhone, iPad, tablet, etc) and, other than for
        setup, it does not require an internet connection.
      </p>
      <p>
        To confirm you have a compatible device, please open this page
        (https://verify.vipdesk.com) on a mobile device. As soon as you sign in
        to this site on that device, your mobile device should appear here.
      </p>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StatCard from "../components/StatCard";
import getDoc from '../utils/getDoc'

export default {
  components: {
    StatCard,
  },
  computed: {
    ...mapState(["session"]),
  },
  async mounted(){
    let results = await getDoc(null, 'mobileDevice', (results) => {
          this.saveMobile(results)
        })
        this.saveMobile(results)
  },
  methods: {
    getHeader(key) {
      let result = "Cell Phone";
      if (key === "tablet") {
        result = "Tablet";
      }
      return result;
    },
    saveMobile(results) {
      if (results && results.length) {
        this.$store.commit("SET_MOBILE", results);
        // store.dispatch('updateResults', {key:'mobile', data: results[0].name})
      } else {
        this.$store.commit("SET_MOBILE", null);
      }
    },
  },
};
</script>

<style>
</style>