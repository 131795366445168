import axios from "axios"
import { v4 as uuidv4 } from 'uuid';

//https://raw.githubusercontent.com/benjaminhoffman/web-performance-optimizations/master/assets/perf_metrics_timeline.png
//https://github.com/benjaminhoffman/web-performance-optimizations/blob/master/Navigation_Timing_API.md
//https://developer.mozilla.org/en-US/docs/Web/API/Resource_Timing_API/Using_the_Resource_Timing_API

let defaultOptions = {
    server: 'https://speed.cloudflare.com',
    ipInfoUrl: `https://ipinfo.io?token=dfb7ba5f6870a8`,
    callback(status, type, payload) {
        // { status, type, payload }
        console.log('callback', { status, type, payload })
    },
}

let online = true
let inFocus = true

function average(values) {
    let total = 0
    for (let i = 0; i < values.length; i += 1) {
        total += values[i]
    }
    return +(total / values.length).toFixed(2)
}

function min(values) {
    return +(Math.min(...values).toFixed(2))
}

function max(values) {
    return +(Math.max(...values).toFixed(2))
}

function median(values) {
    let arr = [...values]
    const half = Math.floor(arr.length / 2)
    arr.sort((a, b) => a - b)
    if (arr.length % 2) return +(arr[half]).toFixed(2)
    return +((arr[half - 1] + arr[half]) / 2).toFixed(2)
}

function quartile(values, percentile) {
    let arr = [...values]
    arr.sort((a, b) => a - b)
    const pos = (arr.length - 1) * percentile
    const base = Math.floor(pos)
    const rest = pos - base
    if (arr[base + 1] !== undefined) {
        return +((arr[base] + rest * (arr[base + 1] - arr[base])).toFixed(2))
    }
    return +(arr[base].toFixed(2))
}

window.addEventListener('online', () => {
    online = navigator.onLine
})

window.addEventListener('offline', () => {
    online = navigator.onLine
})

document.addEventListener('visibilitychange', () => {
    inFocus = !document.hidden
}, false)

// async function getISP(options) {
//     let result = {
//         status: null,
//         statusText: null,
//         data: null,
//         error: null,
//     }
//     let resp = await fetch(options.ipInfoUrl).catch((err) => {
//         result.error = err
//     })
//     if (!resp) {
//         return result
//     }
//     result.status = resp?.status || null
//     result.statusText = resp?.statusText || null
//     result.data = await resp.json().catch((err) => {
//         result.error = err
//     })
//     return result
// }

async function getCloudFlareLocations(options) {
    let result = {
        status: null,
        statusText: null,
        data: null,
        error: null,
    }
    let resp = await fetch(`${options.server}/locations`).catch((err) => {
        result.error = err
    })
    if (!resp) {
        return result
    }
    result.status = resp?.status || null
    result.statusText = resp?.statusText || null
    result.data = await resp.json().catch((err) => {
        result.error = err
    })
    return result
}

function pauseTest(options, payload) {
    return new Promise((resolve) => {
        if (inFocus && online) {
            return resolve()
        }
        options.callback('start', 'pause', {
            ...payload, ...{
                online,
                inFocus
            }
        })
        let timer = setInterval(() => {
            if (inFocus && online) {
                clearInterval(timer)
                options.callback('end', 'pause', {
                    online,
                    inFocus
                })
                return resolve()
            }
        }, 1000)
    })
}

function measureMOS(latency, jitter, packetLoss = 0) {
    let effectiveLatency = latency + jitter * 2 + 10
    let R
    if (effectiveLatency < 160) {
        R = 93.2 - effectiveLatency / 40
    } else {
        R = 93.2 - (effectiveLatency - 120) / 10
    }
    R = R - packetLoss * 2.5
    return 1 + 0.035 * R + 0.000007 * R * (R - 60) * (100 - R)
}


const measureSpeed = (bytes, duration) => {
    return +(((bytes * 8) / (duration / 1000) / (1e6)).toFixed(2))
}

function measureJitter(data = []) {
    return data.reduce((obj, item, index) => {
        if (index == 0) {
            obj = {
                results: [],
                min: null,
                max: null,
                average: null,
                median: null,
                quartile: null,
                current: null,
                value: null,
            }
            return obj
        }
        let jitter = Math.abs(item - data[index - 1])
        obj.results.push(jitter)
        obj.min = min(obj.results)
        obj.max = max(obj.results)
        obj.average = average(obj.results)
        obj.median = median(obj.results)
        obj.quartile = quartile(obj.results, 0.9)
        obj.current = +(jitter.toFixed(2))
        obj.value = obj.median
        return obj
    }, {})
}

const request = async (session, params, options, i) => {
    let id = uuidv4()
    await pauseTest(options, { id, i, ...{ params } })

    let packetLoss = 0
    let direction = params.type === 'upload' ? '__up' : '__down'
    let method = params.type === 'upload' ? 'POST' : 'GET'
    // let uploadStart
    let url = `${options.server}/${direction}?measId=${session}`
    if (method == "GET") {
        url += `&bytes=${params.bytes}`
    }
    let data = params.type === 'upload' ? "0".repeat(params.bytes) : undefined

    // let start = performance.now()
    let resp = await axios({
        url, method, data, headers: {
            'content-type': 'text/plain;charset=UTF-8'
        },
    }).catch((err) => {
        console.log(err)
        packetLoss = 1
    })
    // let end = performance.now()
    if (!resp || resp.status >299 || packetLoss) {
        console.log('PACKET LOSS')
        packetLoss = 1
        let result = {
            id,
            session,
            bytes: 0,
            latency: -1,
            packetLoss,
            speed: 0,
            duration: 0,
            online,
            inFocus,
            statusText: resp?.statusText || null,
            status: resp?.status || -1,
        }
        options.callback(
            'start', 'request', { ...{ id, i, session, params } }
        )
        return result
    }
    let timings = performance.getEntriesByType("resource")
    let timing = (timings || []).filter(resource => resource.name == url).pop()
    if (!timing) {
        console.log('NO TIMINGS', { timings })
        console.log(url, timings.filter(resource => resource.name == url))
    }
    let bytes = params.type === 'upload' ? params.bytes : timing?.encodedBodySize || params.bytes || 0
    let serverTimingHeader = (+(resp.headers['server-timing'] || '').slice(22)) || 0
    let serverTiming = timing?.serverTiming[0] || {}
    let serverTimingTime = serverTiming?.duration || serverTimingHeader
    let ttfb = timing ? timing.responseStart - timing.requestStart : null
    let responseTime = timing.responseEnd - timing.responseStart
    let speedTiming = params.type === 'upload' ? serverTimingTime : responseTime
    let speed = measureSpeed(bytes, speedTiming)
    let latency = serverTimingTime ? ttfb - serverTimingTime : null

    let result = {
        id,
        session,
        bytes,
        latency,
        packetLoss,
        speed,
        duration: +(speedTiming.toFixed(2)),
        online,
        inFocus,
        statusText: resp.statusText,
        status: resp.status,
        headers: params.type === 'headers' ? resp.headers : null
    }
    return result
}

async function measure(session, params, options) {
    let id = uuidv4()
    let result
    let latencyMeasurements = []
    let speedMeasurements = []
    let results = []
    let packetsLost = 0
    options.callback('start', 'batch', {
        ...{ id, session, params },
    })

    for (let i = 0; i < params.iterations; i += 1) {
        options.callback('start', 'request', {
            ...{ id, session, params, i },
        })
        let resp = await request(session, params, options, i)
        results.push(resp)
        if (resp.packetLoss) {
            packetsLost++
        } else {
            latencyMeasurements.push(resp.latency)
            speedMeasurements.push(resp.speed)
        }

        result = {
            id,
            packetsLost,
            packetLoss: packetsLost / params.iterations * 100,
            jitter: measureJitter(latencyMeasurements),
            latency: {
                results: latencyMeasurements,
                min: min(latencyMeasurements),
                max: max(latencyMeasurements),
                average: average(latencyMeasurements),
                median: median(latencyMeasurements),
                quartile: quartile(latencyMeasurements, 0.9),
                current: +(resp.latency.toFixed(2))
            },
            speed: {
                results: speedMeasurements,
                min: min(speedMeasurements),
                max: max(speedMeasurements),
                average: average(speedMeasurements),
                median: median(speedMeasurements),
                quartile: quartile(speedMeasurements, 0.9),
                current: +(resp.speed.toFixed(2))
            },
            results
        }
        result.latency.value = result.latency.median
        result.speed.value = result.speed.quartile

        options.callback('end', 'request', {
            ...{ id, session, params, result },
        })
    }
    options.callback('end', 'batch', {
        ...{ id, session, params, result },
    })
    return result
}

function mbToBytes(bytes) {
    return bytes * 1024 * 1024
}


async function serverTest(session, options) {
    let id = uuidv4()
    options.callback('start', 'server', { id })
    // let isp = await getISP(options)
    let headers = await measure(session, { type: 'headers', bytes: 0, iterations: 1 }, options, 0)
    let locations = await getCloudFlareLocations(options)
    let serverLocation = locations.data.filter(location => location.iata === headers.results[0].headers['cf-meta-colo'])[0]
    let result = {
        id,
        serverLocation,
        // isp: isp.data || isp,
        headers: headers.results[0].headers || headers,
    }
    options.callback('end', 'server', result)
    return result
}

async function networkTest(session, options) {
    let id = uuidv4()
    options.callback('start', 'network', { id })
    let latencyTest1 = await measure(session, { type: 'latency', bytes: 0, iterations: 20 }, options, 1)
    let latency = latencyTest1.latency
    let packetLoss = latencyTest1.packetLoss
    let jitter = latencyTest1.jitter
    let mos = measureMOS(latency.average, jitter.average, packetLoss)


    let result = {
        latency,
        packetLoss,
        jitter,
        mos
    }
    options.callback('end', 'network', result)
    return result
}

async function downloadTest(session, options) {
    let id = uuidv4()
    options.callback('start', 'download', { id })
    let downloadTest1 = await measure(session, { type: 'download', bytes: mbToBytes(.1), iterations: 2 }, options, 2)
    let downloadTest2 = await measure(session, { type: 'download', bytes: mbToBytes(1), iterations: 8 }, options, 4)
    let downloadTest3 = await measure(session, { type: 'download', bytes: mbToBytes(10), iterations: 6 }, options, 6)
    let downloadTest4 = await measure(session, { type: 'download', bytes: mbToBytes(25), iterations: 4 }, options, 8)
    let downloadTest5
    let downloadSpeeds = [
        ...downloadTest1.speed.results,
        ...downloadTest2.speed.results,
        ...downloadTest3.speed.results,
        ...downloadTest4.speed.results,
    ]

    if (quartile(downloadSpeeds, 0.9) > 150) {
        downloadTest5 = await measure(session, { type: 'download', bytes: mbToBytes(50), iterations: 2 }, options, 10)
        downloadSpeeds.push(...downloadTest5.speed.results)
    }

    let download = {
        average: average(downloadSpeeds),
        min: min(downloadSpeeds),
        max: max(downloadSpeeds),
        median: median(downloadSpeeds),
        quartile: quartile(downloadSpeeds, 0.9),
        results: [
            downloadTest1, 
            downloadTest2, downloadTest3, downloadTest4
        ],
    }
    if (downloadTest5) {
        download.results.push(downloadTest5)
    }
    download.value = download.quartile
    let result = {
        download
    }
    options.callback('end', 'download', result)
    return result
}

async function uploadTest(session, options) {
    let id = uuidv4()
    options.callback('start', 'upload', { id })
    let uploadTest1 = await measure(session, { type: 'upload', bytes: mbToBytes(.1), iterations: 8 }, options, 3)
    let uploadTest2 = await measure(session, { type: 'upload', bytes: mbToBytes(1), iterations: 6 }, options, 5)
    let uploadTest3 = await measure(session, { type: 'upload', bytes: mbToBytes(10), iterations: 4 }, options, 7)
    let uploadTest4 = await measure(session, { type: 'upload', bytes: mbToBytes(25), iterations: 4 }, options, 9)

    let uploadSpeeds = [...uploadTest1.speed.results, ...uploadTest2.speed.results, ...uploadTest3.speed.results,
        ...uploadTest4.speed.results
    ]

    let upload = {
        average: average([...uploadSpeeds]),
        min: min(uploadSpeeds),
        max: max(uploadSpeeds),
        median: median(uploadSpeeds),
        quartile: quartile(uploadSpeeds, 0.9),
        results: [uploadTest1, uploadTest2, uploadTest3,
              uploadTest4
        ]
    }
    upload.value = upload.quartile

    let result = {
        upload
    }
    options.callback('end', 'upload', result)
    return result
}

async function speedtest(options) {
    let session = uuidv4()
    let startTime = performance.now()
    let requestOptions = { ...defaultOptions, ...options }

    let serverResult = await serverTest(session, requestOptions)
    let networkResult = await networkTest(session, requestOptions)
    let downloadResult = await downloadTest(session, requestOptions)
    let uploadResult = await uploadTest(session, requestOptions)
    let endTime = performance.now()

    let result = {
        session,
        ...serverResult,
        ...networkResult,
        ...downloadResult,
        ...uploadResult,
        duration: endTime - startTime
    }
    return result
}

export default speedtest
