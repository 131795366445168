let xml2js = require("xml2js")
let dayjs = require("dayjs")
import Bowser from "bowser"
let device = Bowser.parse(window.navigator.userAgent)
let parseError
const parseWindowsNfo = async (xml) => {
    // console.log({ xml })
    parseError = null
    let system = {
        // rawData: xml
    }
    let result = await xml2js.parseStringPromise(xml).catch(error => {
        // console.log('ERROR', error)
        system.parseError = error
        return system
    })
    if (system.parseError) {
        // console.log('GOT ERROR')
        return system
    }
    system.scanned = dayjs(result.MsInfo.Metadata[0].CreationUTC[0] + 'Z', 'MM/DD/YY hh:mm:ssZ').format()
    // console.log({result})
    try {
        result.MsInfo.Category.forEach(root => {
            if (!root.Category || !root.Category.length) {
                return
            }
            root.Category.forEach(category1 => {
                // console.log(category1)
                if (!category1.Category || !category1.Category.length) {
                    return
                }
                category1.Category.forEach(item => {
                    if (item.$.name === 'Storage') {
                        // console.log('******',item)
                        if (!item.Category || !item.Category.length) {
                            return
                        }
                        item.Category.forEach(component => {
                            // console.log(component.$.name,{component})
                            if (component.$.name === 'Drives') {
                                // console.log(component.Data)
                                if (!component.Data || !component.Data.length) {
                                    return
                                }
                                component.Data.forEach(data => {
                                    // console.log(data)
                                    if (data.Item[0] === 'Size' && !system.hardDriveSize) {
                                        let mb = false
                                        let size = data.Value[0].split('GB')[0]
                                        if (!size) {
                                            size = data.Value[0].split('MB')[0]
                                            mb = true
                                        }
                                        if (size) {
                                            size = (+size) / (mb ? 1024 : 1)
                                        }
                                        system.hardDriveSize = size || null
                                    } else if (data.Item[0] === 'Free Space' && !system.hardDriveFreeSpace) {
                                        let mb = false
                                        let size = data.Value[0].split('GB')[0]
                                        if (!size) {
                                            size = data.Value[0].split('MB')[0]
                                            mb = true
                                        }
                                        if (size) {
                                            size = (+size) / (mb ? 1024 : 1)
                                        }
                                        system.hardDriveFreeSpace = size || null
                                    }
                                })
                            }
                        })
                    }
                    // console.log(item, item.$.name )
                })
            })
        })
        result.MsInfo.Category[0].Data.forEach((row) => {
            let name = row.Item.toString()
                .trim()
                .replace(/ - /gim, "_")
                .replace(/[()]/gim, "")
                .replace(/\s/gim, "_")
            if (row.Value[0].match(/( GB)$/)) {
                system[`${name}_Value`] = +row.Value[0].replace(/( GB)$/, "")
            } else if (row.Value[0].match(/( MB)$/)) {
                system[`${name}_Value`] =
                    +row.Value[0].replace(/( MB)$/, "") / 1024
            }
            // if (row.Value[0].match(/(Resolution)$/)) {
            //     let name = row.Item.toString()
            //         .trim()
            //         .replace(/ - /gim, "_")
            //         .replace(/[()]/gim, "")
            //         .replace(/\s/gim, "_")
            // }
            system[name] = row.Value[0]
            if (name === "Processor") {
                let brand = row.Value[0].match(/^[a-zA-Z]+/)
                if (brand) {
                    system.Processor_Brand = brand[0]
                }
                let coreParts = row.Value[0].match(/ (\d+) Core\(s\)/)
                system.Cores = +(coreParts[1] || 0)
                let LpParts = row.Value[0].match(/ (\d+) Logical Processor\(s\)/)
                system.Logical_Processors = +(LpParts[1] || 0)
                let sGParts = row.Value[0].match(/@ (\d+.\d+)GHz/)
                if (sGParts) {
                    system.Processor_Speed = +(sGParts[1] || 0)
                } else {
                    let sMParts = row.Value[0].match(/ (\d+.)MHz/i)
                    if (sMParts) {
                        system.Processor_Speed = +(sMParts[1] || 0) / 1000
                    }
                }
                // Intel(R) Core(TM) i7-10875H CPU @ 2.30GHz, 2304 Mhz, 8 Core(s), 16 Logical Processor(s)
            }
        })

        system.displays = []
        result.MsInfo.Category[0].Category[1].Category.forEach((row) => {
            if (row.$.name === 'Display') {
                system.displays = row.Data.filter(item => item.Item[0] === 'Resolution').map(item => item.Value[0])
            }
        })
    } catch (error) {
        // console.log('CATCH ERR', error)
        parseError = error
        // return Promise.reject({error,system})
    }
    let results = {
        manufacturer: system.System_Manufacturer || 'Unknown',
        model: system.System_Model || 'Unknown',
        os: device.os.name,
        osName: system.OS_Name || 'Unknown',
        osVersion: system.Version ? +system.Version.split(".").slice(0, 2).join(".") : 'Unknown',
        cpu: system.Processor || 'Unknown',
        cpuBrand: (system.Processor || '').match('Intel') ? 'Intel' : (system.Processor || '').match('AMD')  ? 'AMD' : 'Unknown',
        cpuCores: system.Cores || 'Unknown',
        cpuSpeed: system.Processor_Speed || 'Unknown',
        cpuProcessors: system.Logical_Processors || 'Unknown',
        ram: system.Installed_Physical_Memory_RAM_Value || null,
        displays: system.displays ? system.displays.length : 'Unknown',
        scanned: system.scanned,
        date: dayjs().format('YYYY-MM-DD'),
        username: system.User_Name,
        additional: {
            timezone: system.Time_Zone || 'Unknown',
            ramFree: system.Available_Physical_Memory_Value || null,
            ramFreePercent: system.Available_Physical_Memory_Value && system.Installed_Physical_Memory_RAM_Value ? Math.round((system.Available_Physical_Memory_Value / system.Installed_Physical_Memory_RAM_Value) * 100) : null,
            hardDriveSize: system.hardDriveSize || 'Unknown',
            hardDriveFreeSpace: system.hardDriveFreeSpace || 'Unknown',
            hardDriveFreePercent: system.hardDriveFreeSpace && system.hardDriveSize ? Math.round((system.hardDriveFreeSpace / system.hardDriveSize) * 100) : null,
            biosVersionDate: system['BIOS_Version/Date'],
            biosYear: +(system['BIOS_Version/Date'] || '').substr(-4),
            locale: system.Locale,
        },
        parseError
    }
    // console.log({system})
    // console.log(results, "DONE")
    return results
}

export default parseWindowsNfo