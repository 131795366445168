<template>
  <v-card-actions class="d-flex justify-end">
    <v-btn
      :to="to"
      color="#173042"
      :dark="!isDisabled"
      :disabled="isDisabled"
      @click="next"
      >{{ text || "Proceed to Next Step" }}</v-btn
    >
  </v-card-actions>
</template>

<script>
export default {
  data() {
    return {
      isDisabled: true,
    };
  },
  watch: {
    disabled: {
      immediate: true,
      handler(val) {
        if (val) {
          this.isDisabled = true;
        } else {
          setTimeout(() => {
            this.isDisabled = false;
          }, 1000 * (this.delay || 0));
        }
      },
    },
  },
  props: ["disabled", "nextTab", "text", "to", "delay"],
  methods: {
    next() {
      this.$emit("next", this.nextTab);
    },
  },
};
</script>

<style>
</style>