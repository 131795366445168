<template>
  <div>
    <div
      class="d-flex justify-center"
      v-if="testSummary.results.location !== 'missing'"
    >
      <StatCard
        header="Location"
        icon="mdi-map-marker"
        :footer="`${testSummary.location || 'MISSING'}`"
      ></StatCard>
      <StatCard
        v-if="testSummary.isp"
        header="ISP"
        icon="mdi-router-wireless-settings"
        :footer="`${testSummary.isp}`"
      ></StatCard>
      <StatCard
        v-if="testSummary.ip"
        header="IP Address"
        icon="mdi-ip-network-outline"
        :footer="`${testSummary.ip}`"
      ></StatCard>
    </div>
    <!-- <div v-if="!summary.checks.location.missing">
      <div class="d-flex justify-center">
        <StatCard
          :header="summary.checks.location.title"
          :icon="summary.checks.location.icon"
          :footer="`${summary.checks.location.display}`"
        ></StatCard>
      </div>
    </div> -->
    <div>
      <div class="d-flex justify-center">
        <v-card elevation="6" :loading="processing">
          <v-card-text>
            <div v-if="blocked">
              <v-alert type="error">
                You appear to have blocked this site from reading your location.
              </v-alert>
              <p>Please unblock this site and allow access to your location. If you are unsure how, please check out <Article id="browserBlocked" text="this article"/> for instructions on how to fix the issue.</p>
            </div>
            <div v-else-if="processing">
              <div>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>

              <p>
                Requesting location. Please be sure to click allow when
                prompted.
              </p>
            </div>
            <div v-else-if="testSummary.results.location === 'missing' || !testSummary.location">
              <p>
                Even though we have a remote work model, we can only hire in
                certain locations. Because of that, we need to ensure that you
                live in an area we can hire.
              </p>
              <p>
                Please click
                <v-btn x-small dark color="#173042" @click="getNetwork"
                  >HERE</v-btn
                >
                and follow the prompts in your browser.
              </p>
            </div>
            <div v-else>
              <p>
                Great! We have determined your location. Please click Next to
                proceed.
              </p>
              <p>
                <strong>Note</strong>: Sometimes the city and/or ISP information
                may be incorrect or slightly off. In most cases we just need to
                determine your state/province since some positions only allow
                certain locations.
              </p>
            </div>

            <!-- <CheckLocation
              @complete="updateResults"
              @processing="onProcessing"
              @automaticLocation="automaticLocationResults"
            >
              <div slot="complete">
                <div v-speak>
                  <p>
                    Great! We have determined your location. Please click Next
                    to proceed.
                  </p>
                  <p>
                    Note: Sometimes the city may be off. In most cases we just
                    need to determine your state/province.
                  </p>
                </div>
              </div>
              <div slot="blocked">
                <div class="d-flex justify-center">
                  <v-card elevation="6">
                    <v-card-text>
                      <p>Opps. Blocked.</p>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
              <div slot="inprogress">
                <p v-speak>
                  Requesting location. Please be sure to click allow when
                  prompted.
                </p>
              </div>
              <div slot="intro" class="d-flex justify-center">
                <p v-speak>
                  Even though we have a remote work model, we can only hire in
                  certain locations. Because of that, we need to ensure that you
                  live in an area we can hire. Please click
                  <v-btn x-small dark color="#173042">HERE</v-btn> and follow
                  the prompts in your browser.
                </p>
              </div>
            </CheckLocation> -->
            <!-- <v-btn x-small dark color="#173042" @click="getNetwork">HERE</v-btn> and follow -->
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
// import CheckLocation from "../components/CheckLocation";
import StatCard from "../components/StatCard";
import Article from "../components/Article";
import { mapGetters, mapState } from "vuex";
// import { getPreciseDistance } from "geolib";
import getNetwork from "../utils/getNetwork";

export default {
  name: "Location",
  data() {
    return {
      processing: false,
      blocked: false,
      payload: null,
      automaticLocation: null,
      throttle: null,
    };
  },
  components: {
    // CheckLocation,
    StatCard,
    Article
  },
  watch: {
    "$store.state.results.location": {
      immediate: true,
      handler() {
        this.compareLocations();
      },
    },
    "$store.state.history": {
      immediate: true,
      handler() {
        this.compareLocations();
      },
    },
  },
  methods: {
    async getNetwork() {
      this.processing = true;
      this.error = null;
      this.blocked = false;
      this.updateResults(null);
      let data = await getNetwork(this.user)
      // .catch((err) =>
      //   console.log({ err })
      // );
      if (data?.location?.permission === "denied") {
        this.$store.dispatch("updateResults", { key:'internet', data });
        this.$store.dispatch("updateResults", { key:'network', data });
        this.blocked = true;
      }
      if (data) {
        let key = "network";
        this.$store.dispatch("updateResults", { key, data });
      }
      this.processing = false;
    },
    compareLocations() {
      clearTimeout(this.throttle);
      this.throttle = setTimeout(() => {
        let original = this.$store.state.results.location;
        let current = this.automaticLocation;
        if (original && current) {
          // let distance = getPreciseDistance(
          //   original.position,
          //   current.position,
          //   0.01
          // );
          if (original.city !== current.city || original.st !== current.st) {
            this.$store.commit("UPDATE_LOCATION", current);
          }
        }
      }, 200);
    },
    onProcessing(bool) {
      this.processing = bool;
    },
    automaticLocationResults(data) {
      this.automaticLocation = data;
      this.compareLocations();
      //   this.$store.commit("UPDATE_RESULTS", { key: "location", data });
    },
    updateResults(data) {
      let key = "network";
      this.$store.dispatch("updateResults", { key, data });
      //   this.$store.commit("UPDATE_RESULTS", { key: "location", data });
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["summary", "testSummary"]),
  },
  async created() {
    if (navigator.permissions) {
      let locationPerms = await navigator.permissions.query({
        name: "geolocation",
      });
      locationPerms.onchange = () => {
        if (locationPerms.state && locationPerms.state !== "denied") {
          this.getNetwork();
        }
      };
    }
  },
};
</script>

<style>
</style>