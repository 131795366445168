<template>
  <div>
    <template v-if="running">
      <SpeedTestGraph
        class="pr-1"
        height="160"
        width="175"
        color="rgba(255,0,0,0.3)"
        label="Latency (ms)"
        :dataset="chartResults.latency.values"
        :title="chartResults.latency.title"
      />
      <SpeedTestGraph
        class="pr-1"
        height="160"
        width="175"
        color="rgba(0,255,0,0.3)"
        label="Jitter (ms)"
        :dataset="chartResults.jitter.values"
        :title="chartResults.jitter.title"
      />
      <SpeedTestGraph
        class="pr-1"
        height="160"
        width="175"
        color="rgba(255,0,255,0.3)"
        label="Download (MB/s)"
        :dataset="chartResults.download.values"
        :title="chartResults.download.title"
      />
      <SpeedTestGraph
        height="160"
        width="175"
        color="rgba(0,0,255,0.3)"
        label="Upload (MB/s)"
        :dataset="chartResults.upload.values"
        :title="chartResults.upload.title"
      />
    </template>
    <div @click="speedtest">
      <slot name="processing"></slot>
      <slot name="complete"></slot>
      <slot name="intro"></slot>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import speedtest from "./speedtest";
import SpeedTestGraph from "./SpeedTestGraph";

export default {
  name: "CheckInternet",
  components: {
    SpeedTestGraph,
  },
  data() {
    return {
      running: false,
      count: 0,
      result: {},
      chartResults: {
        download: {
          title: "",
          values: [],
        },
        upload: {
          title: "",
          values: [],
        },
        latency: {
          title: "",
          values: [],
        },
        jitter: {
          title: "",
          values: [],
        },
      },
      currentTest: {
        pause: null,
        latency: 0,
        jitter: 0,
        download: 0,
        upload: 0,
      },
    };
  },
  methods: {
    speedtestCallback(status, type, payload) {
      let requestType = payload?.params?.type || type;
      this.$set(this.result, requestType, { status, requestType, payload });
      this.$emit("progress", { status, requestType, payload });

      if (status == "start" && type === "request") {
        switch (requestType) {
          case "download":
          case "upload":
            this.chartResults[requestType].title = `Testing ${
              payload?.params?.bytes / 1024 / 1024
            } MB (${payload?.i + 1}/${payload?.params?.iterations})`;
            break;
          case "latency":
            break;
        }
      }
      if (status == "end" && (type === "request" || type === "batch")) {
        switch (requestType) {
          case "download":
          case "upload":
            this.chartResults[requestType].values.push({
              x: new Date(),
              y: payload?.result?.speed?.current,
            });
            break;
          case "latency":
            this.chartResults.latency.values.push({
              x: new Date(),
              y: payload?.result?.latency?.current,
            });
            if (payload?.result?.jitter?.current) {
              this.chartResults.jitter.values.push({
                x: new Date(),
                y: payload?.result?.jitter?.current,
              });
            }
            break;
        }
      } else if (status == "end") {
        switch (type) {
          case "download":
          case "upload":
            this.currentTest[type] = payload[type]?.value;
            break;
          case "network":
            this.currentTest.latency = payload?.latency.value;
            this.currentTest.jitter = payload?.jitter.value;
            break;
        }
      }
      if (status == "end" && (type === "upload" || type === "download")) {
        this.chartResults[type].title = `Results: ${payload[
          type
        ]?.value.toFixed(1)} MB/s`;
      } else if (status == "end" && type === "network") {
        this.chartResults.latency.title = `Results: ${payload?.latency?.value.toFixed(
          1
        )} ms`;
        this.chartResults.jitter.title = `Results: ${payload?.jitter?.value.toFixed(
          1
        )} ms`;
      }
    },
    reset() {
      this.running = false;
      this.result = {};
      (this.chartResults = {
        download: {
          title: "",
          values: [],
        },
        upload: {
          title: "",
          values: [],
        },
        latency: {
          title: "",
          values: [],
        },
        jitter: {
          title: "",
          values: [],
        },
      }),
        (this.currentTest = {
          pause: null,
          latency: 0,
          jitter: 0,
          download: 0,
          upload: 0,
        });
    },
    async speedtest() {
      if (this.running) {
        return;
      }
      this.reset();
      this.running = true;
      this.$emit("running", true);
      let done = await speedtest({
        callback: this.speedtestCallback,
      }).catch((error) => {
        this.running = false;
        this.$emit("running", false);
        this.$emit("error", { error });
        this.$emit("complete", null);
        return { error };
      });
      this.running = false;
      this.$emit("running", false);
      this.$emit("complete", done);
    },
  },
};
</script>