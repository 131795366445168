<template>
  <div>
    <p>
      Welcome to the verify process. This tool should take less than 5 minutes
      to complete and will help us ensure that your location, ISP, and hardware
      meet our requirements.
    </p>
    <p>Before you begin, a couple of things to note:</p>
    <ul>
      <li>
        Be sure to read every step carefully before performing the required
        steps
      </li>
      <li>
        This tool must be run from your home since we are evaluating your home
        office
      </li>
      <li>This tool must be run from your own computer</li>
      <li>
        To get the best internet results, it is recommended you are connected
        directly to your router or modem vs WiFi
      </li>
      <li>
        To complete this process, you will need to perform the last step on a
        mobile device (cell phone, tablet, etc)
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>