<template>
  <div>
    <div v-if="!processing && testSummary.cpuSpeed" class="d-flex justify-center" >
      <StatCard
        v-if="testSummary.osName"
        header="OS"
        :icon="device.os.name"
        :footer="testSummary.osName"
      ></StatCard>
      <StatCard
        v-if="testSummary.cpuSpeed"
        header="CPU"
        icon="cpu"
        :footer="`${testSummary.cpuSpeed}GHz X ${testSummary.cpuCores} (${testSummary.cpuBrand})`"
      ></StatCard>
      <StatCard
        v-if="testSummary.architecture"
        header="Architecture"
        :icon="`architecture${testSummary.architecture}`"
        :footer="`${testSummary.architecture}-bit`"
      ></StatCard>
      <StatCard
        v-if="testSummary.ram"
        header="RAM"
        icon="ram"
        :footer="`${testSummary.ram}${testSummary.ram ? ' GB' : ''}`"
      ></StatCard>
    </div>
    <v-card elevation="6" :loading="processing">
      <v-card-text>
        <div v-if="!testSummary.cpuSpeed || !testSummary.ram">
          <p>
              Next up is your computer. We need to make sure your hardware is
              powerful enough to handle our applications. For intructions on how
              to generate and upload your system information, click
              <Article :id="`${device.os.name}Info`" text="HERE" />. Once done these steps, return here to upload the file you generate/save.
          </p>
            <p class="text-center"  @click="openFileDialog">
              <v-btn small color="primary" dark>Upload System Information File</v-btn>
            </p>
        </div>
        <div v-if="testSummary.cpuSpeed && testSummary.ram">
           <p>
              Great! We have all of the necessary information about your
              computer to see if it meets our requirements. Please click the
              button below to proceed to the next step.
            </p>
        </div>
        <div>
                   <p>
              <strong>NOTE</strong> If your computer is not set to English as the default
              language, this check will fail. We are working on a way to resolve
              this issue. In the meantime, we will manually review your results
              from this upload.
            </p>
        </div>
         <input
                  type="file"
                  ref="upload"
                  style="display:none;"
                  @cancel="stopProcessing"
                  @blur="stopProcessing"
                  @focus="stopProcessing"
                  @change="parseSystemInfo"
                  :accept="device.os.name === 'Windows' ? '.nfo' : '.spx'"
                />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import StatCard from "../components/StatCard";
import Article from "../components/Article";
import { mapGetters } from "vuex";
import icons from "../utils/icons";
import device from "../utils/device";

import parseWindowsNfo from "../utils/parseWindowsNfo";
import parseMacSpx from "../utils/parseMacSpx";

const architecture =
  device.os.name === "macOS" ||
  navigator.userAgent.indexOf("WOW64") > -1 ||
  navigator.userAgent.indexOf("Win64") > -1
    ? 64
    : 32;

export default {
  name: "Computer",
  data() {
    return {
      onfocus: null,
      processing: false,
      complete: false,
      blocked: false,
      payload: null,
      device,
    };
  },
  components: {
    StatCard,
    Article,
  },
  methods: {
    getIcon(key) {
      switch (key) {
        case "architecture":
          return icons[`architecture${this.testSummary.architecture}`];
        case "os":
          if (this.testSummary.osName.match("Windows")) {
            return "osWindows";
          } else if (this.testSummary.osName.match("macOS")) {
            return "osMac";
          }
          return "warning";
      }
    },
    updateResults(data) {
      this.processing = false;
      this.complete = true;
      let key = "computer";
      this.$store.dispatch("updateResults", { key, data });
    },
    stopProcessing() {
      this.processing = false;
    },
    openFileDialog() {
      this.processing = true;
      this.complete = false;
      this.$refs.upload.click();
      this.$refs.upload.blur();
    },
    async parseSystemInfo(evt) {
      let file = evt.target.files[0];
      if (!file) {
        this.stopProcessing();
        return;
      }
      this.error = null;
      this.system = { startTime: this.startTime, errors: [] };
      let handler;
      if (file.name.match(/.spx$/)) {
        handler = parseMacSpx;
      } else if (file.name.match(/.nfo$/)) {
        handler = parseWindowsNfo;
      } else {
        this.error =
          "Something went wrong and we did not get the file we expected. Please check the instructions and try again from Step 1";
        console.log("Unknown file");
        return;
      }
      let reader = new FileReader();
      //TODO SAVE file in GOOGLE
      reader.onload = (() => {
        return async (e) => {
          let xml = e.target.result;
          let system = await handler(xml);
          if (system.parseError) {
            this.error =
              "Something is wrong with the file you uploaded. We are unable to read it. Please check the instructions and try again from Step 1. If the issue continues, please report the issue.";
            this.processing = false;
            return;
          }
          if (system.error) {
            this.error =
              "Something is wrong with the file you uploaded. We are unable to read it. Please check the instructions and try again from Step 1";
            this.processing = false;
            return;
          }

          this.system = {
            ...{ errors: [] },
            ...this.system,
            ...system,
            ...{ architecture },
          };
          this.complete = true;
          this.processing = false;
          this.updateResults(this.system);
        };
      })(file);
      this.processing = true;
      reader.readAsText(file);
      this.processing = false;
    },
  },
  computed: {
    ...mapGetters(["testSummary"]),
  },
  destroyed() {
    document.body.onfocus = this.onfocus;
  },
  mounted() {
    this.$nextTick(() => {
      this.onfocus = document.body.onfocus;
      document.body.onfocus = () => {
        this.stopProcessing()
      }
    })
    },
};
</script>

<style>
</style>