<template>
  <div>
    <!-- <pre>{{session}}</pre>
    <pre>{{testSummary}}</pre> -->

    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          Congratulations! Your home office meets our requirements
        </v-card-title>
        <v-card-text>
          <v-alert
            type="success"
            outlined
            color="green"
            prominent
            border="left"
          >
            <h2 class="mb-2">
              Verify #{{ session.ticket }}

              <v-progress-circular
                v-if="!session.ticket"
                indeterminate
                :size="25"
                color="primary"
              ></v-progress-circular>
            </h2>
            <p class="mt-5">
              All tests have passed and your results have been submitted.
              <strong>Please write down your Verify number</strong>
              (printed above. you will need this when applying)
            </p>
            <p>
              We would love for you to apply to join our team. Please
              <v-btn
                @click="apply"
                color="primary"
                x-small
                >Apply Now</v-btn
              >
            </p>
          </v-alert>
          <v-alert type="info" outlined dense class="text-caption">
            Please note that this is just a basic test and may need to be
            reviewed upon application.
            <ul>
              <li v-if="device.os.name !== 'Windows'">
                NOTE: MacOS is not supported on all positions. Please review the
                requirements for the position you are applying for.
              </li>
              <li v-if="testSummary.results.webcam == 'missing'">
                NOTE: We require a webcam and we currently do not detect one. If
                hired, you will need one prior to training.
              </li>
              <li>
                NOTE: Even if we detect a headset, some headsets have compatibility issues. We may need to review. If you do not have a headset, you will need one that meets requirements prior to your first day.
              </li>
              <li
                v-if="
                  (testSummary.results.displays !== 'pass' &&
                    !testSummary.displays) ||
                  testSummary.displays < 2
                "
              >
                NOTE: Although we recommend multiple monitors for all team
                members, for some programs it is required. Please review the
                requirements for the position you are applying for.
              </li>
            </ul>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Review Results
          </v-btn>
          <v-btn @click="apply" color="primary"
            >Apply Now</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert
      v-if="missing.length"
      type="warning"
      outlined
      prominent
      border="left"
    >
      It appears you system check was incomplete. We were not able to verify all
      of your components. If you feel this is innacurate, please feel free to
      submit your results for review.
    </v-alert>
    <v-alert
      v-else-if="testSummary.pass"
      type="success"
      outlined
      color="green"
      prominent
      border="left"
    >
      Congratulations! All checks have passed and your results have been
      submitted. Your confirmation number is
      <v-btn color="primary" x-small>{{ session.ticket }}</v-btn> (you will need
      this when applying. Please write it down.). We would love for you to apply
      to join our team. Please
      <v-btn to="/test/apply" color="primary" x-small>Apply Now</v-btn>
    </v-alert>

    <v-alert v-else type="error" outlined prominent border="left">
      Unfortunately you did not pass all of our requirements. With that said, some issues are easily fixed. Please see the table below. NOTE: Not all issues can be fixed so please review the full list before making any costly changes.
    </v-alert>

    <div class="d-flex flex-wrap justify-center">
      <StatCard
        small
        :icon="getIcon('sign')"
        header="Confirm"
        :footer="testSummary.accepted ? states['pass'] : states['fail']"
        :color="testSummary.accepted ? undefined : 'red'"
      />
      <StatCard
        small
        v-for="(item, key) in testSummary.results"
        :key="key"
        :icon="getIcon(key)"
        :header="getTitle(key)"
        :footer="states[item]"
        :tooltip="testSummary[key]"
        :color="
          item === 'pass'
            ? undefined
            : item === 'missing'
            ? 'red'
            : item === 'exempt'
            ? 'orange'
            : 'red'
        "
      />
    </div>
    <v-alert type="info" outlined dense class="text-caption">
      Please note that this is just a basic test and may need to be reviewed
      upon application.
      <ul>
        <li v-if="device.os.name !== 'Windows'">
          NOTE: MacOS is not supported on all positions. Please review the
          requirements for the position you are applying for.
        </li>
        <li v-if="testSummary.results.webcam == 'missing'">
          NOTE: We require a webcam and we currently do not detect one. If
          hired, you will need one prior to training.
        </li>
        <li>
          NOTE: Even if we detect a headset, some headsets have compatibility
          issues. We may need to review.
        </li>
        <li
          v-if="
            (testSummary.results.displays !== 'pass' &&
              !testSummary.displays) ||
            testSummary.displays < 2
          "
        >
          NOTE: Although we recommend multiple monitors for all team members,
          for some programs it is required. Please review the requirements for
          the position you are applying for.
        </li>
      </ul>
    </v-alert>
    <!-- <div>PASS: {{testSummary.pass}}</div>
    <div>fails: {{testSummary.fails}}</div>
    <div>missing: {{testSummary.missing}}</div> -->
    <!-- <pre>
          {{ device }}
      </pre>
      -->
    <template v-if="fails.length">
      <!-- <v-toolbar dark color="primary" dense>
        <h3>Results</h3>
      </v-toolbar> -->
      <div>
        <v-alert type="warning">
          <p>
          Below are the test that may have issues or do not meet our minimum
          requirements. Please review as some are easily fixed. </p> <p>NOTE: Not all issues can be fixed so please review the full list before making any costly changes. </p></v-alert
        >
      </div>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Test</th>
              <th class="text-left">Result</th>
              <th class="text-left">Status</th>
              <th class="text-left">Help</th>
              <th class="text-left">Retry</th>
            </tr>
          </thead>
          <tbody>
            <tr dense v-if="!testSummary.accepted">
              <td>Confirm</td>
              <td class="pa-3">Not Confirmed</td>
              <td>{{ states.fail }}</td>
              <td></td>
              <td>

              <router-link tag="div" :to="`/test/confirm`">
                    <v-icon>mdi-restore</v-icon>
                </router-link>
              </td>
            </tr>
            <template v-for="item in fails">
              <tr dense v-if="!item.pass" :key="item.title">
                <td>{{ getTitle(item.key) }}</td>
                <td class="pa-3">
                  <span v-if="item.state !== 'missing'">
                    {{ item.value }}
                  </span>
                  <div v-for="msg in item.messages" :key="msg">
                    <!-- <v-icon
                    :color="
                      item.pass
                        ? 'green'
                        : item.missing || item.exempt
                        ? 'orange'
                        : 'red'
                    "
                    >{{
                      item.pass
                        ? "mdi-clipboard-check"
                        : item.missing
                        ? "mdi-alert"
                        : item.exempt
                        ? "mdi-information"
                        : "mdi-close-octagon"
                    }}</v-icon
                  > -->
                    {{ msg }}
                  </div>

                  <!-- {{item}} -->
                </td>
                <td>{{ states[item.state] }}</td>
                <td>
                  <Article
                    v-if="item.state !== 'pass'"
                    :id="item.key"
                    :icon="item.icon"
                    text="Learn More"
                  />
                </td>
                <td>
                  <router-link
                    tag="div"
                    v-if="item.state === 'missing' || (item.state !== 'pass' && item.retry)"
                    :to="`/test/${item.retry}/${item.key}`"
                  >
                    <v-icon>mdi-restore</v-icon>
                  </router-link>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </template>
    <!-- <v-btn @click="testAgain">Test Again</v-btn> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
// import firebase from "firebase";
import StatCard from "../StatCard";
import Article from "../Article";
import device from "../../utils/device";
import icons from "../../utils/icons";
export default {
  components: {
    StatCard,
    Article,
  },
  data() {
    return {
      dialog: false,
      device,
      states: {
        pass: "Pass",
        fail: "Fail",
        missing: "Missing",
        exempt: "TDB",
      },
    };
  },
  methods: {
    apply(){
      this.dialog = false
      this.$router.push({name:'Test', params:{step:'apply'}}).catch(()=>{})
    },
    // async postResults() {
    //   let createTicket = firebase.functions().httpsCallable("createTicket");
    //   let resp = await createTicket(this.testSummary);
    //   console.log(resp);
    //   this.$store.commit("SET_TICKET", resp?.data?.ticket?.id || null);
    // },
    testAgain() {
      this.$router.push("/test/intro");
      this.$store.commit("RESET", true);
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    getTitle(key) {
      switch (key) {
        case "downloadSpeed":
          return "Download";
        case "uploadSpeed":
          return "Upload";
        case "os":
        case "cpu":
        case "ram":
          return key.toUpperCase();
        default:
          return this.toTitleCase(key);
      }
    },
    getIcon(key) {
      switch (key) {
        case "architecture": {
          return this.testSummary.architecture
            ? icons[`${key}${this.testSummary.architecture}`]
            : icons.warning;
        }
        case "os": {
          let os = device.os.name;
          if (os == "Windows") {
            return icons.osWindows;
          } else if (os == "macOS") {
            return icons.osMac;
          }
          return icons.warning;
        }
        default:
          return icons[key] || null;
      }
    },
  },
  watch: {
    "testSummary.complete": {
      immediate: true,
      handler(val) {
        // console.log(val);
        if (val === 1) {
          // this.postResults();
        }
        if (val === 1 && this.testSummary.pass) {
          this.dialog = true;
        }
      },
    },
  },
  computed: {
    ...mapState(["results", "session"]),
    ...mapGetters(["testSummary"]),
    missing() {
      let arr = [];
      Object.keys(this.testSummary.results).forEach((key) => {
        if (this.testSummary.results[key] === "missing") {
          arr.push(key);
        }
      });
      return arr;
    },
    fails() {
      let arr = [];
      Object.keys(this.testSummary.results).forEach((key) => {
        if (this.testSummary.results[key] !== "pass") {
          let result = {
            key,
            value: this.testSummary[key],
            state: this.testSummary.results[key],
            retry: null,
          };
          if (this.testSummary.results[key] !== "missing") {
            if (key === "os") {
              result.value = this.testSummary.osName;
            } else if (key === "downloadSpeed" || key === "uploadSpeed") {
              result.value += " MB/s";
            } else if (key === "latency" || key === "jitter") {
              result.value += " ms";
            } else if (key === "architecture") {
              result.value += "-bit";
            } else if (key === "ram") {
              result.value += " GB";
            }
          }
          switch (key) {
            case "downloadSpeed":
            case "uploadSpeed":
            case "jitter":
            case "latency":
              result.retry = "internet";
              break;
            case "location":
              result.retry = "location";
              break;
            case "displays":
            case "resolution":
              result.retry = "displays";
              break;
            case "headset":
            case "webcam":
              result.retry = "devices";
              break;
            case "mobile":
              result.retry = "mobile";
              break;
          }

          arr.push(result);
        }
      });
      return arr;
    },
    //     passFail(){
    //         return [
    //             {
    //                 name: 'OS',
    //                 value: this.results.computer.osName,
    //                 passFail: true
    //             },
    //             {
    //                 name: 'CPU',
    //                 value: this.results.computer.osName,
    //                 passFail: true
    //             },
    //             {
    //                 name: 'RAM',
    //                 value: this.results.computer.osName,
    //                 passFail: true
    //             },
    //             {
    //                 name: 'Location',
    //                 value: this.results.computer.osName,
    //                 passFail: true
    //             },
    //             {
    //                 name: 'Download Speed',
    //                 value: this.results.internet.download.value + 'MB/s',
    //                 passFail: true
    //             },
    //             {
    //                 name: 'Upload Speed',
    //                 value: this.results.internet.upload.value + 'MB/s',
    //                 passFail: true
    //             },
    //             {
    //                 name: 'Internet Latency',
    //                 value: this.results.internet.latency.value + 'ms',
    //                 passFail: true
    //             },
    //             {
    //                 name: 'Internet Jitter',
    //                 value: this.results.internet.jitter.value + 'ms',
    //                 passFail: true
    //             },
    //             {
    //                 name: 'Webcam',
    //                 value: this.results.internet.jitter.value + 'ms',
    //                 passFail: true
    //             },
    //             {
    //                 name: 'Monitors',
    //                 value: this.results.displays,
    //                 passFail: true
    //             },

    //         ]
    //     }
  },
  mounted() {
    this.dialog = this.testSummary.complete === 1 && this.testSummary.pass;
  },
};
</script>

<style>
</style>