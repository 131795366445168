<template>
  <div>
    <slot v-if="isOpen" name="processing" />
    <div v-else @click="open">
      <slot></slot>
    </div>
       <PopUp
      :open="isOpen"
      v-model="payload"
      @close="close"
      :boxWidth="boxWidth"
      :boxHeight="boxHeight"
    >
      <slot name="popup" />
    </PopUp>
     <!-- devicePixelRatio {{ devicePixelRatio }}
    <HR></HR>
    otherDevicePixelRatio {{ otherDevicePixelRatio(currentDisplay) }}
    <HR></HR>
    currentDisplay {{ currentDisplay }}
    <HR></HR>
    otherDisplay {{ otherDisplay(currentDisplay) }}
    <HR></HR>
    <pre>
      {{ payload }}
    </pre> -->
  </div>
</template>

<script>
import PopUp from "./PopUp.vue";
import { mdiMonitor } from "@mdi/js";
export default {
  name: "CheckDisplays",
  mounted() {},
  data() {
    return {
      boxWidth: 500,
      boxHeight: 400,
      isOpen: false,
      payload: {},
      icon: mdiMonitor,
    };
  },
  watch: {
    displayCount(val, oldVal) {
      if (val !== !oldVal) {
        console.log('CHANGE',this.payload.displays)
        this.$emit("change", this.payload.displays);
      }
    },
    isOpen(val) {
      this.$emit("processing", val);
    },
    // payload: {
    //   handler(val, oldVal) {
    //     console.log('WATCH PAYLOAD',val,oldVal)
    //     if (
    //       JSON.stringify(val?.currentDisplay || {}) !=
    //       JSON.stringify(oldVal?.currentDisplay || {})
    //     ) {
    //       console.log("CHANGE");
    //       // this.$emit("change", this.payload.displays);
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  computed: {
    displayCount() {
      if (!this.payload || !this.payload.displays) {
        return 0;
      }
      console.log('displayCount',this.payload.displays)
      return Object.keys(this.payload.displays).length;
    },
    currentDisplay() {
      console.log('CURRENT DISPLAY',this.payload.currentDisplay,this.payload.displays)
      return this.payload.currentDisplay
        ? this.payload.displays[this.payload.currentDisplay] || null
        : null;
    },

    devicePixelRatio() {
      let devicePixelRatio = null;
      if (!this.payload.displays || !this.payload.currentDisplay) {
        return 1;
      }
      Object.keys(this.payload.displays).forEach((id) => {
        let display = this.payload.displays[id];
        if (!devicePixelRatio || display.devicePixelRatio < devicePixelRatio) {
          devicePixelRatio = display.devicePixelRatio;
        }
      });
      return devicePixelRatio;
    },
  },
  methods: {
    otherDisplay(device) {
      return this.payload.displays
        ? this.payload.displays[
            Object.keys(this.payload?.displays || {}).filter(
              (id) => id != device.id
            )[0] || "x"
          ] || this.currentDisplay
        : this.currentDisplay;
    },
    otherDevicePixelRatio(device) {
            console.log('otherDevice',device)
      let otherDevice = this.otherDisplay(device);
      return otherDevice ? otherDevice.devicePixelRatio : this.devicePixelRatio;
    },
    move() {
      // console.log("MOVE ", data);
      // this.payload = { ...this.payload, ...data };
    },
    close() {
      this.isOpen = false;
      console.log("CHANGE");
      this.$emit("complete", this.payload.displays);
    },
    open() {
      this.isOpen = true;
    },
  },
  components: {
    PopUp,
  },
};
</script>